/*
 * Project: OKIT.VCM
 *
 * Copyright 2023 by OKIT GmbH
 * All rights reserved.
 *
 * Diese Software ist urheberrechtlich geschützt.
 */
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import CasClient, { constant } from "react-cas-client";
import { CasUserContext } from "../context/casUserContext";
import axios from "axios";
import apiList from "../utils/apiClient.js";
import { getDeviceApi } from "../hooks/deviceApi.js";
import { CheckTokenMeasurementApi } from "../hooks/measurementApi.js";
import { authAnonymous } from "../hooks/anonymousApi.js";

/**
 *
 */
const casWrapper = () => {
  const casClient = new CasClient(process.env.REACT_APP_CAS_ENDPOINT, {
    version: constant.CAS_VERSION_3_0,
  });

  casClient._validateTicket = (ticket, resolve, reject) =>
    axios
      .post(apiList.CasClient + ticket, {
        service: casClient.redirectUrl,
      })
      .then((response) => {
        //console.log("response: ",response.data)
        let user = response.data.user;
        let roles = response.data.roles;
        localStorage.setItem("roles", roles);
        casClient._handleSuccessValdiate(resolve, user, null);

        // check for token for each device
        getDeviceApi().then(async (response) => {
          const deviceList = response ? response : [];
          if (deviceList.length > 0) {
            // check every device for token
            CheckTokenMeasurementApi(deviceList)
              .then((resp) => {
                //console.log("resp:", resp)
              })
              .catch((err) => {
                console.log(err);
              });
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  return casClient;
};
const useCas = (attempLoginWithGateway = false) => {
  const navigate = useNavigate();
  const { user, login, logout } = useContext(CasUserContext);
  const [isLoading, setIsLoading] = useState(false);

  const casClient = casWrapper();

  function casByPass() {
    console.log("Start to use Anonymous mode ...");
    (async function () {
      try {
        await authAnonymous().then((response) => {
          //console.log("response ", response);
          let user = response.user;
          let roles = response.roles;
          let clientPrefix = response.clientPrefix;

          localStorage.setItem("roles", roles);
          localStorage.setItem("clientPrefix", clientPrefix);
          localStorage.setItem("user", user);

          setIsLoading(false);
          //localStorage.setItem("hasRedirected", "true");
          navigate("/devices", { replace: true });
        });
      } catch (error) {
        console.error("error ", error);
      }
    })();
  }

  function casLoginNullClientPrefix() {
    console.log("Start to use CAS ...");
    (async function () {
      try {
        await attemptCasLogin(attempLoginWithGateway);
      } catch (error) {
        console.error("error ", error);
      }
    })();
  }

  function casLogin() {
    if (!user.auth) {
      console.log("Start to use CAS ...");
      (async function () {
        try {
          await attemptCasLogin(attempLoginWithGateway);
        } catch (error) {
          console.error("error ", error);
        }
      })();
    }
  }

  function attemptCasLogin() {
    return new Promise((resolve, reject) => {
      casClient
        .auth()
        .then((successRes) => {
          login(successRes.user);
          setIsLoading(false);
          //localStorage.setItem("hasRedirected", "true");
          //console.log("done...");
          //navigate("/devices", { replace: true });
          setTimeout(() => {
            navigate("/devices", { replace: true });
          }, 1000);
        })
        .catch((errorRes) => {
          console.error("errorRes ", errorRes);
          setIsLoading(false);
          //navigate(errorRes.currentPath, { replace: true });
          setTimeout(() => {
            navigate(errorRes.currentPath, { replace: true });
          }, 1000);
          reject(errorRes);
        });
    });
  }

  function casLogout() {
    localStorage.removeItem("zoomLevelLat");
    localStorage.removeItem("zoomLevelLong");
    localStorage.removeItem("clientPrefix");
    localStorage.removeItem("roles");
    localStorage.removeItem("user");
    localStorage.removeItem("zoomLevel");
    localStorage.removeItem("position");
    logout();
    casClient.logout();
  }

  return {
    isLoading,
    casLogin,
    casLogout,
    casByPass,
    casLoginNullClientPrefix
  };
};

export default useCas;
