/*
 * Project: OKIT.VCM
 *
 * Copyright 2023 by OKIT GmbH
 * All rights reserved.
 *
 * Diese Software ist urheberrechtlich geschützt.
 */
import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import TimeOutToast from "../../utils/TimeOutToast";
import {
  getMeasurementByNameSpaceApi,
  setMeasurementByNameSpaceApi,
} from "../../hooks/measurementApi";
import useWindowSize from "../../utils/useWindowSize";
import { Switch } from "@mui/material";
import greenIcon from "../../assets/images/state-green.svg";
import redIcon from "../../assets/images/state-red-danger.svg";
import lockerON from "../../assets/images/lockerON.svg";
import lockerOff from "../../assets/images/lockerOff.svg";

/**
 * This component represents the the VehicleManagement
 *
 *
 * @author hatem sfar
 *
 */
export default function VehicleManagement({ device }) {
  const [dataInfo, setDataInfo] = useState({
    Milage: "",
    WorkingHours: "",
    CrashDetection: "",
    TowDetection: "",
    GEOFenceLeft: "",
    GEOFenceEntered: "",
    MaintenanceRequired: "",
    DriverName: "",
    DriverSpeedometerCardID: "",
    TruckID: "",
    NumberPlate: "",
    MaxkmOfTyres: "",
    MinTreadDepthOfTyres: "",
    LastMaintenanceKmOfDeviceItself: "",
    LastMaintenanceHoursOfDeviceItself: "",
    LockState: "",
    HasLockState: "",
  });
  const [lockState, setLockState] = useState(false);
  const [hasLockState, setHasLockState] = useState();
  const windowSize = useWindowSize();

  useEffect(() => {
    getMeasurement();
    // eslint-disable-next-line
  }, [device]);

  /**
   * get all the measurement that we need
   */
  const getMeasurement = async () => {
    const position =
      "de.okit.automotive.device.Milage" +
      "," +
      "de.okit.automotive.device.WorkingHours" +
      "," +
      "de.okit.automotive.device.CrashDetection" +
      "," +
      "de.okit.automotive.device.TowDetection" +
      "," +
      "de.okit.automotive.device.GEOFenceLeft" +
      "," +
      "de.okit.automotive.device.GEOFenceEntered" +
      "," +
      "de.okit.automotive.device.MaintenanceRequired" +
      "," +
      "de.okit.automotive.device.DriverName" +
      "," +
      "de.okit.automotive.device.DriverSpeedometerCardID" +
      "," +
      "de.okit.automotive.device.TruckID" +
      "," +
      "de.okit.automotive.device.NumberPlate" +
      "," +
      "de.okit.automotive.device.MaxkmOfTyres" +
      "," +
      "de.okit.automotive.device.MinTreadDepthOfTyres" +
      "," +
      "de.okit.automotive.device.LastMaintenanceKmOfDeviceItself" +
      "," +
      "de.okit.automotive.device.LastMaintenanceHoursOfDeviceItself" +
      "," +
      "de.okit.automotive.device.LockState" +
      "," +
      "de.okit.automotive.device.HasLockState";

    const namespaceToStateKey = {
      "de.okit.automotive.device.Milage": "Milage",
      "de.okit.automotive.device.WorkingHours": "WorkingHours",
      "de.okit.automotive.device.CrashDetection": "CrashDetection",
      "de.okit.automotive.device.TowDetection": "TowDetection",
      "de.okit.automotive.device.GEOFenceLeft": "GEOFenceLeft",
      "de.okit.automotive.device.GEOFenceEntered": "GEOFenceEntered",
      "de.okit.automotive.device.MaintenanceRequired": "MaintenanceRequired",
      "de.okit.automotive.device.DriverName": "DriverName",
      "de.okit.automotive.device.DriverSpeedometerCardID":
        "DriverSpeedometerCardID",
      "de.okit.automotive.device.TruckID": "TruckID",
      "de.okit.automotive.device.NumberPlate": "NumberPlate",
      "de.okit.automotive.device.MaxkmOfTyres": "MaxkmOfTyres",
      "de.okit.automotive.device.MinTreadDepthOfTyres": "MinTreadDepthOfTyres",
      "de.okit.automotive.device.LastMaintenanceKmOfDeviceItself":
        "LastMaintenanceKmOfDeviceItself",
      "de.okit.automotive.device.LastMaintenanceHoursOfDeviceItself":
        "LastMaintenanceHoursOfDeviceItself",
      "de.okit.automotive.device.LockState": "LockState",
      "de.okit.automotive.device.HasLockState": "HasLockState",
    };
    try {
      const response = await getMeasurementByNameSpaceApi(device.id, position);

      if (response) {
        const updatedDataInfo = { ...dataInfo };

        response.forEach((info) => {
          const stateKey = namespaceToStateKey[info.namespace];
          if (stateKey && info.measurements && info.measurements.length > 0) {
            updatedDataInfo[stateKey] = info.measurements[0].content || "";
          }
        });

        console.log("updatedDataInfo ", updatedDataInfo);
        setLockState(updatedDataInfo?.LockState === "true");
        setHasLockState(updatedDataInfo?.HasLockState === "true");
        setDataInfo(updatedDataInfo);
      }
    } catch (error) {
      if (error.response && error.response.status === 504) {
        console.error("Gateway Time-out: The server took too long to respond.");
        TimeOutToast();
      }
    }
  };

  /**
   *
   * @param {*} e
   * change the state of Lock state and add new measurement
   */
  const handleChangeLockState = (e) => {
    const newValue = e.target.checked;
    setLockState(newValue);
    const namespace = "de.okit.automotive.device.LockState";
    try {
      setMeasurementByNameSpaceApi(device.id, newValue, namespace).then(() => {
        getMeasurement();
      });
    } catch (err) {
      if (err.response && err.response.status === 504) {
        console.error("Gateway Time-out: The server took too long to respond.");
        TimeOutToast();
      }
      console.log(err);
    }
  };

  return (
    <Container
      fluid
      style={{ width: windowSize[0] > 991 ? "50%" : "100%", float: "left" }}
    >
      <h3
        style={{ marginLeft: "15px", marginTop: "10px" }}
        className="mb-4 pb-2 pb-md-0 mb-md-5 px-md-2"
      >
        {i18next.t("VehicleManagement")}{" "}
      </h3>

      <Row style={{ marginTop: "10px" }}>
        <Col>
          <font size="3" style={{}}>
            {i18next.t("DeviceName")}
          </font>
        </Col>
        <Col>{device?.name}</Col>
      </Row>

      <Row style={{ marginTop: "10px" }}>
        <Col>
          <font size="3" style={{}}>
            {i18next.t("Milage")}
          </font>
        </Col>
        <Col>
          {Number(dataInfo?.Milage).toLocaleString("en-de")} {" km"}
        </Col>
      </Row>

      <Row style={{ marginTop: "10px" }}>
        <Col>
          <font size="3" style={{}}>
            {i18next.t("WorkingHours")}
          </font>
        </Col>
        <Col>
          {Number(dataInfo?.WorkingHours).toLocaleString("en-de")}{" "}
          {i18next.t("Hours")}
        </Col>
      </Row>

      <Row style={{ marginTop: "10px" }}>
        <Col>
          <font size="3" style={{}}>
            {i18next.t("CrashDetection")}
          </font>
        </Col>
        <Col>
          {dataInfo?.CrashDetection === "" ? (
            <img alt="" src={greenIcon} height="20" />
          ) : dataInfo?.CrashDetection === "false" ? (
            <img alt="" src={greenIcon} height="20" />
          ) : (
            <img alt="" src={redIcon} height="20" />
          )}
        </Col>
      </Row>

      <Row style={{ marginTop: "10px" }}>
        <Col>
          <font size="3" style={{}}>
            {i18next.t("TowDetection")}
          </font>
        </Col>
        <Col>
          {dataInfo?.TowDetection === "" ? (
            <img alt="" src={greenIcon} height="20" />
          ) : dataInfo?.TowDetection === "false" ? (
            <img alt="" src={greenIcon} height="20" />
          ) : (
            <img alt="" src={redIcon} height="20" />
          )}
        </Col>
      </Row>

      <Row style={{ marginTop: "10px" }}>
        <Col>
          <font size="3" style={{}}>
            {i18next.t("GEOFenceLeft")}
          </font>
        </Col>
        <Col>
          {dataInfo?.GEOFenceLeft === "" ? (
            <img alt="" src={greenIcon} height="20" />
          ) : dataInfo?.GEOFenceLeft === "false" ? (
            <img alt="" src={greenIcon} height="20" />
          ) : (
            <img alt="" src={redIcon} height="20" />
          )}
        </Col>
      </Row>

      <Row style={{ marginTop: "10px" }}>
        <Col>
          <font size="3" style={{}}>
            {i18next.t("GEOFenceEntered")}
          </font>
        </Col>
        <Col>
          {dataInfo?.GEOFenceEntered === "" ? (
            <img alt="" src={greenIcon} height="20" />
          ) : dataInfo?.GEOFenceEntered === "false" ? (
            <img alt="" src={greenIcon} height="20" />
          ) : (
            <img alt="" src={redIcon} height="20" />
          )}
        </Col>
      </Row>

      <Row style={{ marginTop: "10px" }}>
        <Col>
          <font size="3" style={{}}>
            {i18next.t("MaintenanceRequired")}
          </font>
        </Col>
        <Col>{dataInfo?.MaintenanceRequired}</Col>
      </Row>

      <Row style={{ marginTop: "10px" }}>
        <Col>
          <font size="3" style={{}}>
            {i18next.t("DriverName")}
          </font>
        </Col>
        <Col>{dataInfo?.DriverName}</Col>
      </Row>

      <Row style={{ marginTop: "10px" }}>
        <Col>
          <font size="3" style={{}}>
            {i18next.t("DriverSpeedometerCardID")}
          </font>
        </Col>
        <Col>{dataInfo?.DriverSpeedometerCardID}</Col>
      </Row>

      <Row style={{ marginTop: "10px" }}>
        <Col>
          <font size="3" style={{}}>
            {i18next.t("TruckID")}
          </font>
        </Col>
        <Col>{dataInfo?.TruckID}</Col>
      </Row>

      <Row style={{ marginTop: "10px" }}>
        <Col>
          <font size="3" style={{}}>
            {i18next.t("NumberPlate")}
          </font>
        </Col>
        <Col>{dataInfo?.NumberPlate}</Col>
      </Row>

      <Row style={{ marginTop: "10px" }}>
        <Col>
          <font size="3" style={{}}>
            {i18next.t("maxkmoftyres")}
          </font>
        </Col>
        <Col>
          {Number(dataInfo?.MaxkmOfTyres).toLocaleString("en-de")} {" km"}
        </Col>
      </Row>

      <Row style={{ marginTop: "10px" }}>
        <Col>
          <font size="3" style={{}}>
            {i18next.t("mintreaddepthoftyres")}
          </font>
        </Col>
        <Col>
          {dataInfo?.MinTreadDepthOfTyres.replace(".", ",")} {" mm"}
        </Col>
      </Row>

      <Row style={{ marginTop: "10px" }}>
        <Col>
          <font size="3" style={{}}>
            {i18next.t("lastmaintenancekmofdeviceitself")}
          </font>
        </Col>
        <Col>
          {Number(dataInfo?.LastMaintenanceKmOfDeviceItself).toLocaleString(
            "en-de"
          )}{" "}
          {" km"}
        </Col>
      </Row>

      <Row style={{ marginTop: "10px" }}>
        <Col>
          <font size="3" style={{}}>
            {i18next.t("lastmaintenancehoursofdeviceitself")}
          </font>
        </Col>
        <Col>
          {Number(dataInfo?.LastMaintenanceHoursOfDeviceItself).toLocaleString(
            "en-de"
          )}{" "}
          {i18next.t("Hours")}
        </Col>
      </Row>

      {hasLockState && (
        <>
          <Row style={{ marginTop: "10px" }}>
            <Col>
              <font size="3" style={{}}>
                {i18next.t("LockState")}
              </font>
            </Col>
            <Col>
              {localStorage.getItem("user") !== "Anonymous" && (
                <Switch
                  checked={lockState}
                  onChange={handleChangeLockState}
                  color="primary"
                  inputProps={{ "aria-label": "lock switch" }}
                />
              )}
              {lockState ? (
                <img alt="" src={lockerON} height="20" />
              ) : (
                <img alt="" src={lockerOff} height="20" />
              )}
            </Col>
          </Row>
        </>
      )}

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </Container>
  );
}
