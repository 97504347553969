/*
 * Project: OKIT.VCM
 *
 * Copyright 2023 by OKIT GmbH
 * All rights reserved.
 *
 * Diese Software ist urheberrechtlich geschützt.
 */
import axios from "axios";
import apiList from "../utils/apiClient.js";

/**
 * This component implements the api's call
 *
 * @author tim hoffmann
 *
 **/

const getAnalyticsReportApi = async (device, isoDateStart, isoDateEnd) => {
  //console.log("device: ", device);
  //console.log("isoDateStart: ", isoDateStart);
  //console.log("isoDateEnd: ", isoDateEnd);

  const headers = {
    accept: "*/*",
    "content-type": "arraybuffer",
    /* "content-type":
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    */
  };

  await axios
    .get(apiList.AnalyticsReport, {
      headers,
      responseType: "blob",
      params: {
        deviceId: device.id,
        intervalStart: isoDateStart,
        intervalEnd: isoDateEnd,
      },
    })
    .then((response) => {
      //console.log("Response in Client: ", response);
      //console.log("Response data in Client: ", response.data);

      // Create a URL for the blob object
      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Create a link element
      const link = document.createElement("a");
      link.href = url;

      // Set the file name for download
      link.setAttribute("download", `report_${device.name}.xls`);

      // Append the link to the document body
      document.body.appendChild(link);

      // Trigger the download
      link.click();

      // Clean up and remove the link element
      link.parentNode.removeChild(link);

      return response.data;
    })
    .catch((error) => {
      console.error("Error in Axios: ", error);
      return error;
    });
};

export { getAnalyticsReportApi };
