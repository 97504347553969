/*
 * Project: OKIT.VCM
 *
 * Copyright 2023 by OKIT GmbH
 * All rights reserved.
 *
 * Diese Software ist urheberrechtlich geschützt.
 */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import i18next from "i18next";
import { iconAlertGood, iconAlertWarning } from "../../utils/iconAlert.js";
import "../../assets/css/deviceDetailsView.css";
import useWindowSize from "../../utils/useWindowSize";
import {
  getMeasurementByNameSpaceApi,
  getTiresMeasurementApi,
} from "../../hooks/measurementApi";
import { ChangeDataTires } from "../../utils/changeDataWS";
import TimeOutToast from "../../utils/TimeOutToast.js";
//import HistoryWarnings from "./HistoryWarnings.js";

/**
 * This component represents the the DeviceDetailsView
 * represent the dynamic tires wih thier infos.
 *
 * @author hatem sfar
 *
 */
export default function TireWeight({ device }) {
  const [tires, setTires] = useState([]);
  const [show, setShow] = useState(false);
  const { id } = useParams();
  const windowSize = useWindowSize();
  const [isLoading, setIsLoading] = useState(true);
  const [axleWeights, setAxleWeights] = useState([]);
  const [deviceWeights, setDeviceWeights] = useState(0);

  /**
   *
   */
  useEffect(() => {
    //console.log("first time");
    getTires();
    //eslint-disable-next-line
  }, []);

  /**
   * to get the tires of the selected device
   */
  const getTires = () => {
    getTiresMeasurementApi(id)
      .then((response) => {
        if (response.error) {
          setTires([]);
        } else {
          // Transform data
          const transformedData = response?.measurements?.flatMap(
            (measurement) =>
              measurement.content.split(",").map((val) => {
                const [var1, axle, direction, position] = val.split(".");
                return {
                  var1,
                  axle: Number(axle),
                  direction,
                  position: Number(position),
                };
              })
          );

          // Map over transformedData array and make axios call for each position
          const axiosCalls = transformedData?.map(async (position) => {
            const position_namespace =
              "de.okit.automotive.tire." +
              position.var1 +
              "." +
              position.axle +
              ".Trailer" +
              "," +
              "de.okit.automotive.tire." +
              position.var1 +
              "." +
              position.axle +
              ".Steering" +
              "," +
              "de.okit.cargotrailsense." +
              position.var1 +
              "." +
              position.axle +
              "." +
              position.direction +
              "." +
              position.position +
              ".LoadMeasured" +
              "," +
              "de.okit.cargotrailsense." +
              position.var1 +
              "." +
              position.axle +
              "." +
              position.direction +
              "." +
              position.position +
              ".MaxWeight" +
              "," +
              "de.okit.cargotrailsense." +
              position.var1 +
              "." +
              position.axle +
              "." +
              position.direction +
              "." +
              position.position +
              ".MinWeight";

            return await getMeasurementByNameSpaceApi(id, position_namespace)
              .then((response) => {
                response &&
                  response?.forEach((data) => {
                    if (data && data.namespace.endsWith("Steering")) {
                      if (data.measurements && data.measurements.length > 0) {
                        const stringValue = data?.measurements[0]?.content;
                        const booleanValue = stringValue === "true";
                        position.Steering = Boolean(booleanValue);
                        position.Timestamp_Steering =
                          data.measurements &&
                          data?.measurements[0]?.measurementdate;
                      }
                    }
                    if (data && data.namespace.endsWith("Trailer")) {
                      if (data.measurements && data.measurements.length > 0) {
                        const stringValue = data?.measurements[0]?.content;
                        const booleanValue = stringValue === "true";
                        position.Trailer = Boolean(booleanValue);
                        position.Timestamp_Trailer =
                          data.measurements &&
                          data?.measurements[0]?.measurementdate;
                      }
                    }
                    if (data && data.namespace.endsWith("LoadMeasured")) {
                      if (data.measurements && data.measurements.length > 0) {
                        position.Weight = Math.round(
                          Number(data?.measurements[0]?.content)
                        );
                        position.Timestamp_Weight =
                          data?.measurements[0]?.measurementdate;
                      }
                    }
                    if (data && data.namespace.endsWith("MaxWeight")) {
                      if (data.measurements && data.measurements.length > 0) {
                        position.MaxWeight = data?.measurements[0]?.content;
                        position.Timestamp_MaxWeight =
                          data?.measurements[0]?.measurementdate;
                      }
                    }
                    if (data && data.namespace.endsWith("MinWeight")) {
                      if (data.measurements && data.measurements.length > 0) {
                        position.MinWeight = data?.measurements[0]?.content;
                        position.Timestamp_MinWeight =
                          data?.measurements[0]?.measurementdate;
                      }
                    }
                  });
              })
              .catch((error) => {
                if (error.response && error.response.status === 504) {
                  console.error(
                    "Gateway Time-out: The server took too long to respond."
                  );
                  TimeOutToast();
                  setIsLoading(false);
                }
              });
          });

          if (!axiosCalls) {
            setShow(true);
            setIsLoading(false);
          }
          // Wait for all axios calls to complete using Promise.all()
          axiosCalls &&
            Promise.all(axiosCalls).then(() => {
              setTires(transformedData ? transformedData : []);
              generateAxleWeightList(transformedData);
              //console.log("transformedData ", transformedData);
              setShow(true);
              setIsLoading(false);
            });
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 504) {
          console.error(
            "Gateway Time-out: The server took too long to respond."
          );
          TimeOutToast();
          setIsLoading(false);
        }
        console.log(err);
        setShow(true);
        setIsLoading(false);
      });
  };

  const lineStyle = {
    gridColumn: "3 / 4", // span from column 3 to column 4
    gridRow: "1 / -1", // span from row 1 to the last row
    zIndex: "100", // set a high value to put the line in front of other elements
    justifySelf: "center",
  };

  const gridStyle = {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 30% 1fr  1fr",
    gap: "1px",
    padding: "0px",
    width: windowSize[0] < 991 ? "100%" : "80%",
  };

  const cardStyle = {
    background: "#e5e5e5",
    width: "100%",
    height: windowSize[0] > 991 ? "140px" : "100px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "25px",
    cursor: "pointer",
    zIndex: "100",
  };

  const getPositionRow = (currentPosition) => {
    if (currentPosition?.Trailer && !currentPosition?.Steering) {
      return currentPosition?.axle + 60;
    }
    if (currentPosition?.Trailer) {
      return currentPosition?.axle + 0;
    }
    if (!currentPosition?.Trailer && !currentPosition?.Steering) {
      return currentPosition?.axle + 20;
    }
    if (currentPosition?.Steering) {
      return currentPosition?.axle;
    }
  };

  const getSizeGridScreen = () => {
    if (windowSize[0] < 1200) {
      return "90%";
    } else {
      return "60%";
    }
  };

  /**
   *
   */
  const cardAxleWeightStyle = {
    background: "#e5e5e5",
    border: "solid 2px #8c8cf0",
    width: "130px",
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "25px",
    //cursor: "pointer",
    zIndex: "100",
    marginLeft: windowSize[0] > 991 ? "50px" : "0px",
  };

  /**
   *
   * @param {*} Weight
   * @param {*} MaxWeight
   * @param {*} MinWeight
   * @returns the color of the tire based on the weight
   */
  function getTireStatusColor(Weight, MaxWeight, MinWeight) {
    if (Weight > MaxWeight || Weight < MinWeight) {
      return "solid 5px orange";
    } else {
      return "solid 5px #18bd18";
    }
  }

  /**
   *
   * @param {*} listAxle
   */
  const generateAxleWeightList = (listAxle) => {
    let newAxleWeights = {};
    let totalTruckWeight = 0;
    listAxle.forEach((tire) => {
      const axle = tire.axle;
      const weight = parseFloat(tire.Weight);
      if (!isNaN(weight)) {
        if (newAxleWeights[axle]) {
          newAxleWeights[axle] += weight;
        } else {
          newAxleWeights[axle] = weight;
        }
        totalTruckWeight += weight;
      }
    });
    setAxleWeights(newAxleWeights);
    setDeviceWeights(totalTruckWeight);
  };

  return (
    <>
      <div style={{ width: "100%" }}>
        <h3
          style={{ marginLeft: "15px", marginTop: "10px" }}
          className="mb-4 pb-2 pb-md-0 mb-md-5 px-md-2"
        >
          {i18next.t("TireWeight")}{" "}
        </h3>
        <ChangeDataTires
          tires={tires}
          setTires={setTires}
          generateAxleWeightList={generateAxleWeightList}
          device={device}
        />
        <div style={{ textAlign: "-webkit-center" }}>
          <br />
          {isLoading && (
            <>
              <div
                className="loading"
                style={{
                  position: "absolute",
                }}
              >
                <div className="loader"></div>
                <div className="text">{i18next.t("Loading")}...</div>
              </div>
            </>
          )}
          <div
            style={{
              textAlign: "-moz-center",
              width: getSizeGridScreen(),
            }}
          >
            <h3
              style={{
                background: "#e5e5e5",
                border: "solid 2px #8c8cf0",
                borderRadius: "25px",
                width: windowSize[0] > 991 ? "500px" : "auto",
                marginLeft: "15px",
                marginTop: "10px",
              }}
              className="mb-4 pb-2 pb-md-0 mb-md-5 px-md-2"
            >
              {i18next.t("totalTruckWeight")} <br />
              {deviceWeights.toLocaleString("en-de") + " kg"}
            </h3>

            <div id="grid" style={gridStyle}>
              {Array.isArray(tires)
                ? tires?.map((position, i) => {
                    const gridRow = getPositionRow(position);
                    const Weight = position?.Weight && Number(position?.Weight);
                    const MaxWeight =
                      position?.MaxWeight && Number(position?.MaxWeight);
                    const MinWeight =
                      position?.MinWeight && Number(position?.MinWeight);

                    return (
                      <React.Fragment key={i}>
                        <div
                          className="lineStyle"
                          style={{
                            ...cardAxleWeightStyle,
                            gridRow: gridRow,
                          }}
                        >
                          {Number(axleWeights[position?.axle]).toLocaleString(
                            "en-de"
                          ) + " kg"}
                        </div>

                        <hr
                          className="horizontalLineStyleV2"
                          style={{
                            gridRow: gridRow,
                            border: position?.Trailer
                              ? "solid 2px #292727"
                              : "solid 2px gray",
                          }}
                        ></hr>

                        <div
                          style={{
                            ...cardStyle,
                            border:
                              !isNaN(Weight) &&
                              !isNaN(MaxWeight) &&
                              !isNaN(MinWeight)
                                ? getTireStatusColor(
                                    Weight,
                                    MaxWeight,
                                    MinWeight
                                  )
                                : "solid 5px orange",

                            transform: position?.Steering && "rotate(30deg)",
                            gridColumn:
                              position?.direction === "left"
                                ? position?.position
                                : position?.direction === "right" &&
                                  position?.position === 1
                                ? position?.position + 4
                                : position?.position + 2, // left column if direction is left, right column if direction is right
                            gridRow: gridRow, // row number is equal to the axle number
                            gap: position?.position === 2 && "2/4", // span two columns for positions 2 and 3
                          }}
                        >
                          <h3
                            style={{
                              color: "black",
                              //transform: position?.Steering && "rotate(-30deg)",
                            }}
                          >
                            {isNaN(Weight) ||
                            isNaN(MaxWeight) ||
                            isNaN(MinWeight) ||
                            Number(Weight) > Number(MaxWeight) ||
                            Number(Weight) < Number(MinWeight) ? (
                              <>
                                <img
                                  src={iconAlertWarning}
                                  height="30"
                                  className="d-inline-block"
                                  alt="warning signe"
                                />
                                <br />
                                <b
                                  className={" value"}
                                  style={{
                                    fontSize:
                                      windowSize[0] > 700 ? "18px" : "10px",
                                    marginLeft: "0px",
                                    // display: isBlur && "none"
                                  }}
                                >
                                  {position?.Weight !== null &&
                                  !isNaN(position.Weight)
                                    ? Number(position?.Weight).toLocaleString(
                                        "en-de"
                                      ) + " kg"
                                    : "-"}
                                </b>
                              </>
                            ) : (
                              <>
                                <img
                                  src={iconAlertGood}
                                  height="30"
                                  className="d-inline-block"
                                  alt="greenLED signe"
                                />
                                <br />
                                <b
                                  className={" value"}
                                  style={{
                                    fontSize:
                                      windowSize[0] > 700 ? "18px" : "10px",
                                    marginLeft: "0px",
                                  }}
                                >
                                  {" "}
                                  {position?.Weight !== null &&
                                  !isNaN(position.Weight)
                                    ? Number(position?.Weight).toLocaleString(
                                        "en-de"
                                      ) + " kg"
                                    : "-"}
                                </b>
                              </>
                            )}
                          </h3>
                        </div>

                        <div style={lineStyle}></div>
                      </React.Fragment>
                    );
                  })
                : null}
            </div>
          </div>

          {!isLoading && tires.length === 0 ? (
            show ? (
              <>
                <div
                  style={{ marginTop: "20px" }}
                  className="d-flex align-items-center justify-content-center"
                >
                  <div className="text-center">
                    <p className="fs-3">
                      {" "}
                      <span className="text-danger"></span>
                      {i18next.t("noTires")}{" "}
                    </p>
                    <a href="/devices" className="btn btn-primary">
                      {i18next.t("GoBack")}
                    </a>
                  </div>
                </div>
              </>
            ) : null
          ) : null}
        </div>
        <br />
        <br /> <br />
        <br /> <br />
        <br />
      </div>
    </>
  );
}
