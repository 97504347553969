/*
 * Project: OKIT.VCM
 *
 * Copyright 2023 by OKIT GmbH
 * All rights reserved.
 *
 * Diese Software ist urheberrechtlich geschützt.
 */
import React, { useEffect, useState } from "react";
import "../../assets/css/sidebar.css";
import "../../assets/css/switchButton.css";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import SearchBar from "./SearchBar";
import CardList from "./CardList";
import i18next from "i18next";
import useWindowSize from "../../utils/useWindowSize";
import { getCoordinateslistApi } from "../../hooks/coordinatesApi";
import { AiOutlineSetting } from "react-icons/ai";
import { Col, Container, Row } from "react-bootstrap";
import { Switch } from "@mui/material";

/**
 * This component represents the the sideBar to update the value
 * for the selected namespace
 *
 * @author hatem sfar
 *
 */
const SideBar = ({
  device,
  isLoading,
  onData,
  hoveredCoordinate,
  deviceCoordinate,
  isAnalytic,
  coordinate,
  setCoordinates,
  selectedDevice,
  setSelectedDevice,
  setShowSpeedometer,
  setIsDevicesSideBarOpen,
  isDevicesSideBarOpen,
  isGeofencing,
}) => {
  // eslint-disable-next-line
  const [open, setOpen] = useState(isDevicesSideBarOpen);
  const [sidebarVisible, setSidebarVisible] = useState(open);
  //const [devices, setDevices] = useState([]);
  const [filteredCards, setFilteredCards] = useState([]);
  const [filteredCardsDevices, setFilteredCardsDevices] = useState([]);
  const windowSize = useWindowSize();
  const [isDevicesHidden, setIsDevicesHidden] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);

  /**
   *
   */
  useEffect(() => {
    // Retrieve the buttonChecked status from localStorage when the component mounts
    const cacheIsDevicesHidden = localStorage.getItem("hiddenDevices");
    if (cacheIsDevicesHidden !== null) {
      setIsDevicesHidden(cacheIsDevicesHidden === "true");
    }
    // filter the devices list
    if (cacheIsDevicesHidden === "true") {
      const filtered = device.filter((card) => card.active);
      setFilteredCards(filtered);
      setFilteredCardsDevices(filtered);
    } else {
      setFilteredCards(device);
      setFilteredCardsDevices(device);
    }

    // eslint-disable-next-line
  }, [device]);

  /**
   *
   */
  useEffect(() => {
    // Save the buttonChecked status to localStorage whenever it changes
    localStorage.setItem("hiddenDevices", isDevicesHidden);

    if (isDevicesHidden) {
      // to filter devices list
      const filtered = device.filter((card) => card.active);
      setFilteredCards(filtered);
      setFilteredCardsDevices(filtered);

      // filter coordinates
      // Extract the IDs of the filtered devices
      const filteredDeviceIds = filtered.map((card) => card.id);

      if (!filteredDeviceIds.includes(selectedDevice)) {
        setSelectedDevice(null);
        setShowSpeedometer(false);
      }
      // Filter the list of coordinates based on the filtered device IDs
      const filteredCoordinates = coordinate.filter((coord) =>
        filteredDeviceIds.includes(coord.device)
      );
      setCoordinates(filteredCoordinates);
    } else {
      setFilteredCards(device);
      setFilteredCardsDevices(device);
      // filter coordinates
      const devicesList = device.map((device) => ({
        id: device.id,
        name: device.name,
        alert: device.alert,
      }));

      getCoordinateslistApi(devicesList).then((response) => {
        const coordinateList = response ? response : [];
        setCoordinates(coordinateList);
      });
    }
    // eslint-disable-next-line
  }, [isDevicesHidden]);

  useEffect(() => {
    if (isGeofencing || isAnalytic) {
      handleCloseSidebar();
    }
    // eslint-disable-next-line
  }, [isAnalytic, isGeofencing]);

  /**
   * to handle open the sideBar
   */
  const handleOpenSidebar = () => {
    setOpen(true);
    setSidebarVisible(true);
    setTimeout(() => {
      setIsDevicesSideBarOpen(true);
    }, 300);
  };
  /**
   * to handle the close of the sideBar
   */
  const handleCloseSidebar = () => {
    setOpen(false);
    setTimeout(() => {
      setSidebarVisible(false);
      setIsDevicesSideBarOpen(false);
    }, 300); // Adjust the delay time to match the animation duration
  };
  /**
   * to send the info to the parent component
   * @param {*} data
   */
  const handleDataFromChild = (data) => {
    // Pass the data to the parent component
    onData(data);
  };

  /**
   * to handle the switch button
   */
  function toggleValue() {
    setIsDevicesHidden(!isDevicesHidden);
  }

  /**
   * Open dialog when button is clicked
   */
  const handleButtonClick = () => {
    setOpenDialog(!openDialog);
  };

  /**
   *  Close dialog when needed
   */
  // eslint-disable-next-line
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <>
      {!open && (
        <MenuRoundedIcon
          onClick={handleOpenSidebar}
          name="menu"
          className="sideBar_open"
        />
      )}

      {sidebarVisible && (
        <div
          style={{
            display: "flex",
            position: "absolute",
            height: "91vh",
            width: windowSize[0] < 991 && "100%",
            marginTop: windowSize[0] < 991 && "0px",
            zIndex: windowSize[0] < 991 && 9999,
          }}
        >
          <div
            style={{
              width: windowSize[0] < 991 && "100%",
              marginTop: windowSize[0] < 991 && "0px",
              padding: "5px",
            }}
            className={`sidebar ${open ? "" : "removeSideBar"}`}
          >
            <div
              className="sidebar_header"
              style={{
                float: "right",
                marginTop: "8px",
                width: windowSize[0] > 991 ? "320px" : "100%",
              }}
            >
              <h4
                style={{
                  color: "rgb(140, 140, 240)",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                {i18next.t("Devices")}
              </h4>
              <ArrowBackIosNewIcon
                onClick={handleCloseSidebar}
                style={{
                  marginRight: "15px",
                  cursor: "pointer",
                  color: "gray",
                }}
              />
            </div>
            <br />
            <br />
            <div
              style={{
                display: "inline-flex",
                flexDirection: "row",
                alignItems: "center",
                flexWrap: "nowrap",
                width: "100%",
              }}
            >
              <div style={{ width: "inherit" }}>
                <SearchBar
                  cards={filteredCardsDevices}
                  setFilteredCards={setFilteredCards}
                />
              </div>
              <div className="dropdown-container">
                <button
                  id="dialog"
                  type="button"
                  className="btn btn-link text-primary"
                  onClick={handleButtonClick}
                >
                  <i>
                    <AiOutlineSetting />
                  </i>
                </button>
                {openDialog && (
                  <div
                    className="dropdown-box"
                    style={{ left: windowSize[0] < 991 && "0" }}
                  >
                    <Container fluid>
                      <Row>
                        <Col>
                          <font size="2" style={{}}>
                            {i18next.t("show_hidden_devices")}:
                          </font>
                        </Col>
                        <Col
                          style={{
                            maxWidth: "55px",
                            marginRight: "20px",
                          }}
                        >
                          <Switch
                            checked={!isDevicesHidden}
                            onChange={toggleValue}
                          />
                        </Col>
                      </Row>
                    </Container>
                  </div>
                )}
              </div>
            </div>

            <br />
            <br />
            <div className="cardlist" style={{ height: "calc(100% - 160px)" }}>
              <CardList
                style={{ zIndex: "1000" }}
                cards={filteredCards}
                isLoading={isLoading}
                onData={handleDataFromChild}
                hoveredCoordinate={hoveredCoordinate}
                deviceCoordinate={deviceCoordinate}
                closeSideBar={handleCloseSidebar}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SideBar;
