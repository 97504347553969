import React from "react";
import {
  iconAlertGood,
  iconAlertWarning,
  iconAlertError,
} from "./iconAlert.js";

const tyreColor = (position) => {
  //console.log("position ", position);
  const Pressure = position?.Pressure && Number(position?.Pressure);
  const PressureLowerAlarm =
    position?.PressureLowerAlarm && Number(position?.PressureLowerAlarm);
  const PressureUpperAlarm =
    position?.PressureUpperAlarm && Number(position?.PressureUpperAlarm);
  const PressureLowerWarn =
    position?.PressureLowerWarn && Number(position?.PressureLowerWarn);
  const PressureUpperWarn =
    position?.PressureUpperWarn && Number(position?.PressureUpperWarn);

  const Temperature = position?.Temperature && Number(position?.Temperature);
  const TemperatureWarn =
    position?.TemperatureWarn && Number(position?.TemperatureWarn);
  const TemperatureAlarm =
    position?.TemperatureAlarm && Number(position?.TemperatureAlarm);
  // Check if all values are valid numbers
  if (
    !isNaN(Pressure) &&
    !isNaN(PressureLowerAlarm) &&
    !isNaN(PressureUpperAlarm) &&
    !isNaN(PressureLowerWarn) &&
    !isNaN(PressureUpperWarn) &&
    !isNaN(Temperature) &&
    !isNaN(TemperatureWarn) &&
    !isNaN(TemperatureAlarm)
  ) {
    if (
      Pressure <= PressureLowerAlarm ||
      Pressure >= PressureUpperAlarm ||
      Temperature >= TemperatureAlarm
    ) {
      return "solid 5px red";
    } else if (
      Pressure <= PressureLowerWarn ||
      Pressure >= PressureUpperWarn ||
      Temperature >= TemperatureWarn
    ) {
      return "solid 5px orange";
    } else {
      return "solid 5px #18bd18";
    }
  } else {
    return "solid 5px orange";
  }
};

const tyreLogo = (position, isPopUp) => {
  //console.log("position ", position);
  const Pressure = position?.Pressure && Number(position?.Pressure);
  const PressureLowerAlarm =
    position?.PressureLowerAlarm && Number(position?.PressureLowerAlarm);
  const PressureUpperAlarm =
    position?.PressureUpperAlarm && Number(position?.PressureUpperAlarm);
  const PressureLowerWarn =
    position?.PressureLowerWarn && Number(position?.PressureLowerWarn);
  const PressureUpperWarn =
    position?.PressureUpperWarn && Number(position?.PressureUpperWarn);

  const Temperature = position?.Temperature && Number(position?.Temperature);
  const TemperatureWarn =
    position?.TemperatureWarn && Number(position?.TemperatureWarn);
  const TemperatureAlarm =
    position?.TemperatureAlarm && Number(position?.TemperatureAlarm);
  // Check if all values are valid numbers
  return (
    <>
      {isNaN(Pressure) ||
      isNaN(PressureLowerAlarm) ||
      isNaN(PressureUpperAlarm) ||
      isNaN(PressureLowerWarn) ||
      isNaN(PressureUpperWarn) ||
      isNaN(Temperature) ||
      isNaN(TemperatureWarn) ||
      isNaN(TemperatureAlarm) ? (
        <>
          <img
            src={iconAlertWarning}
            height="30"
            className="d-inline-block"
            alt="warning signe"
          />
          <br />
        </>
      ) : Pressure <= PressureLowerAlarm ||
        Pressure >= PressureUpperAlarm ||
        Temperature >= TemperatureAlarm ? (
        <>
          <img
            src={iconAlertError}
            height={isPopUp ? "20" : "30"}
            className="d-inline-block"
            alt="Error signe"
          />
          <br />
        </>
      ) : Pressure <= PressureLowerWarn ||
        Pressure >= PressureUpperWarn ||
        Temperature >= TemperatureWarn ? (
        <>
          <img
            src={iconAlertWarning}
            height={isPopUp ? "20" : "30"}
            className="d-inline-block"
            alt="warning signe"
          />
          <br />
        </>
      ) : (
        <>
          <img
            src={iconAlertGood}
            height={isPopUp ? "20" : "30"}
            className="d-inline-block"
            alt="Good signe"
          />
          <br />
        </>
      )}
    </>
  );
};

export { tyreColor, tyreLogo };
