/*
 * Project: OKIT.VCM
 *
 * Copyright 2023 by OKIT GmbH
 * All rights reserved.
 *
 * Diese Software ist urheberrechtlich geschützt.
 */

import { Routes, Route, Navigate } from "react-router-dom";
import useCas from "./auth/useCas";
import auth from "./auth/auth";
import ROLES from "./utils/roles";
import Logout from "./components/Logout";
import Footer from "./components/bars/Footer";
import NavbarTop from "./components/bars/NavbarTop";
import "./assets/css/index.css";
import { ToastContainer } from "react-toastify";
import "./assets/css/reactToasty.css";
import { Map, DeviceDetailView, Measurement, NotFound } from "./pages/index";
import { WebSocketProvider } from "./components/webSocket/WebSocketContext";
import { ToastAlert } from "./utils/toastAlert";
import Setting from "./pages/settingUser/Setting";
import { useNavigate } from "react-router-dom";
import { checkServerHealthApi } from "./hooks/deviceApi";
import { useEffect, useRef, useState } from "react";
import ServerNotReachable from "./utils/serverNotReachable";

/**
 * This component implements the main run for vcm client
 *
 * @author hatem sfar
 *
 */
function App() {
  //console.log("start now")

  // check the url if the url is demo must call api
  // to set session for clientprefix and roles and user to demo mode

  const cas = useCas();
  // cas.casLogin();
  const [serverDown, setServerDown] = useState(false);
  const previousServerDown = useRef(false);
  const navigate = useNavigate();
  const clientPrefix = localStorage.getItem("clientPrefix");

  /**
   * handling the Demo mode
   */
  useEffect(() => {
    const setupDemoMode = async () => {
      const url = window.location.pathname;
      try {
        // If it's in demo mode and anonymous, bypass CAS
        if (url.includes("/demo")) {
          console.log("setupDemoMode");
          cas.casByPass();
        } else if (
          !clientPrefix ||
          clientPrefix === null ||
          clientPrefix === "null"
        ) {
          console.log("casLogin ...");
          cas.casLoginNullClientPrefix();
        }
        // If redirection hasn't occurred yet, redirect and set flag
        else if (url.includes("/devices") && !clientPrefix) {
          console.log("setupNormalMode");
          cas.casLogin();
        }
      } catch (error) {
        console.error("Error setting demo mode:", error);
      }
    };

    setupDemoMode();
    //eslint-disable-next-line
  }, [cas]);

  /**
   * check Server Health
   */

  useEffect(() => {
    const checkServerHealth = async () => {
      try {
        //console.log("serverDown ", serverDown);
        checkServerHealthApi()
          .then((resp) => {
            setServerDown(false);
          })
          .catch((error) => {
            setServerDown(true);
          });
      } catch (error) {
        setServerDown(true);
      }
    };

    const interval = setInterval(checkServerHealth, 5000); // Check every 5 seconds

    return () => clearInterval(interval);
    //eslint-disable-next-line
  }, []);

  /**
   * handling server down
   */

  useEffect(() => {
    if (
      previousServerDown.current !== serverDown &&
      clientPrefix !== "Anonymous"
    ) {
      console.log("Go to serverNotReachable!");
      if (serverDown) {
        navigate("/serverNotReachable");
      } else {
        // must reload from the cas (logout and login)
        console.log("Server Back live!");
        cas.casLogout();
        cas.casLogin();
        navigate("/");
      }
      previousServerDown.current = serverDown;
    }
    //eslint-disable-next-line
  }, [serverDown, navigate]);

  return (
    <>
      <WebSocketProvider>
        <div className="App">
          <ToastAlert />
          {auth([ROLES.USER, ROLES.ADMIN, ROLES.OPERATOR, ROLES.ANONYMOUS]) ? (
            <NavbarTop />
          ) : null}

          <div style={{ flex: "1", overflow: "auto" }}>
            <ToastContainer />
            <Routes>
              <Route path="/" element={<Navigate to="/devices" />} />
              <Route
                path="/devices"
                element={
                  auth([
                    ROLES.USER,
                    ROLES.ADMIN,
                    ROLES.OPERATOR,
                    ROLES.ANONYMOUS,
                  ]) ? (
                    <Map />
                  ) : null
                }
              />
              <Route
                path="/devices/detail/:id"
                element={
                  auth([
                    ROLES.USER,
                    ROLES.ADMIN,
                    ROLES.OPERATOR,
                    ROLES.ANONYMOUS,
                  ]) ? (
                    <DeviceDetailView />
                  ) : null
                }
              />
              <Route
                path="/measurement"
                element={
                  auth([ROLES.OPERATOR, ROLES.ADMIN]) ? <Measurement /> : null
                }
              />
              <Route
                path="/user/settings"
                element={
                  auth([
                    ROLES.OPERATOR,
                    ROLES.ADMIN,
                    ROLES.USER,
                    ROLES.ANONYMOUS,
                  ]) ? (
                    <Setting />
                  ) : null
                }
              />
              <Route path="/logout" element={<Logout />} />
              <Route path="/?status=" />
              <Route path="/demo" />
              <Route path="/*" element={<NotFound />} />
              <Route
                path="/serverNotReachable"
                element={<ServerNotReachable />}
              />
            </Routes>
          </div>
          {auth([ROLES.USER, ROLES.ADMIN, ROLES.OPERATOR, ROLES.ANONYMOUS]) ? (
            <Footer />
          ) : null}
        </div>
      </WebSocketProvider>
    </>
  );
}

export default App;
