/*
 * Project: OKIT.VCM
 *
 * Copyright 2023 by OKIT GmbH
 * All rights reserved.
 *
 * Diese Software ist urheberrechtlich gesch├╝tzt.
 */
import React, { useEffect, useState } from "react";
import {
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import i18next from "i18next";
import { TagsInput } from "react-tag-input-component";
import { Button, Switch, Tooltip } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import { clientPrefixUserUpdateApi } from "../../hooks/clientPrefixApi";
import { Loading } from "../../utils/loading";
import { clientPrefixUserApi } from "../../hooks/clientPrefixApi";
import auth from "../../auth/auth";
import ROLES from "../../utils/roles";
import useWindowSize from "../../utils/useWindowSize";
import { toast } from "react-toastify";
import {
  sendMAILTestDeviceApi,
  sendSMSTestDeviceApi,
} from "../../hooks/deviceApi";

/**
 * This component is for the user to update his personal informations
 *
 * @author hatem sfar
 *
 */
const ClientInfo = () => {
  const [emailTags, setEmailTags] = useState([]);
  const [emailActive, setEmailActive] = useState(false);
  const [numberTags, setNumberTags] = useState([]);
  const [smsActive, setSmsActive] = useState(false);
  const [mute, setMute] = useState(false);
  const [clientActive, setClientActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const windowSize = useWindowSize();

  /**
   *
   */
  useEffect(() => {
    getClient();
    //eslint-disable-next-line
  }, []);

  /**
   *
   */
  const getClient = () => {
    try {
      clientPrefixUserApi().then((data) => {
        const timer = setTimeout(() => {
          setEmailTags(data.reportEmail || []);
          setEmailActive(data.reportActive || false);
          setNumberTags(data.smsNumbers || []);
          setSmsActive(data.smsActive || false);
          setMute(data.mute || false);
          setClientActive(data.active || false);
          setIsLoading(false);
        }, 100); // Adjust the delay time as needed
        return () => clearTimeout(timer);
      });
    } catch (err) {
      if (err.response && err.response.status === 504) {
        console.error("Gateway Time-out: The server took too long to respond.");
      }
      console.log(err);
    }
  };

  /**
   * Update client object with new values
   */
  const updateClient = (client) => {
    if (auth([ROLES.ANONYMOUS])) {
      toast.warning(i18next.t("anonymousTOAST"), {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        hideProgressBar: true,
        theme: "colored",
        style: {
          top: "50px",
        },
      });
    } else {
      try {
        clientPrefixUserUpdateApi(client).then((data) => {
          if (data.status === 500) {
            toast.error(i18next.t("wrrongFormat"), {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000,
              hideProgressBar: true,
              theme: "colored",
              style: {
                top: "50px",
              },
            });
          }
        });
      } catch (err) {
        console.log("Error response from server:", err);

        if (err.response) {
          console.log("Error response from server:", err.response.data);
        } else if (err.request) {
          console.log("No response received from server:", err.request);
        } else {
          console.log("Error setting up the request:", err.message);
        }
        if (err.response && err.response.status === 504) {
          console.log("Gateway Time-out: The server took too long to respond.");
        }
      }
    }
  };

  /**
   *
   * @param {*} newTags
   */
  const handleEmailTagsChange = (newTags) => {
    if (auth([ROLES.ANONYMOUS])) {
      toast.warning(i18next.t("anonymousTOAST"), {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        hideProgressBar: true,
        theme: "colored",
        style: {
          top: "50px",
        },
      });
    } else {
      const emailsContent = newTags.length === 0 ? ["undefined"] : newTags;
      emailsContent[0] !== "undefined" && setEmailTags(emailsContent);
      const updatedClient = {
        emails: emailsContent,
        emailActive: emailActive,
        smsActive: smsActive,
        mute: mute,
        clientActive: clientActive,
      };
      emailTags !== emailsContent && updateClient(updatedClient);
    }
  };

  /**
   *
   * @param {*} newTags
   */
  const handleNumberTagsChange = (newTags) => {
    if (auth([ROLES.ANONYMOUS])) {
      toast.warning(i18next.t("anonymousTOAST"), {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        hideProgressBar: true,
        theme: "colored",
        style: {
          top: "50px",
        },
      });
    } else {
      // Filter the tags that start with '0049' or '+49'
      const validTags = newTags.filter(
        (tag) => tag.startsWith("0049") || tag.startsWith("+49")
      );

      // If there are any invalid tags, show the toast error
      if (newTags.length !== validTags.length) {
        toast.warning(i18next.t("wrongnumberformat"), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
          hideProgressBar: true,
          theme: "colored",
          style: {
            top: "50px",
          },
        });
      }

      // If there are valid tags, update the state and the client
      const smsNumbersContent =
        validTags.length === 0 ? ["undefined"] : validTags;
      if (smsNumbersContent[0] !== "undefined")
        setNumberTags(smsNumbersContent);

      const updatedClient = {
        numbers: smsNumbersContent,
        emailActive: emailActive,
        smsActive: smsActive,
        mute: mute,
        clientActive: clientActive,
      };

      if (numberTags !== validTags) updateClient(updatedClient);
    }
  };

  /**
   * to handle the input for boolean type
   */
  const handleCheckEmail = (e) => {
    if (auth([ROLES.ANONYMOUS])) {
      toast.warning(i18next.t("anonymousTOAST"), {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        hideProgressBar: true,
        theme: "colored",
        style: {
          top: "50px",
        },
      });
    } else {
      setEmailActive(e.target.checked);
      const updatedClient = {
        emailActive: e.target.checked,
        smsActive: smsActive,
        mute: mute,
        clientActive: clientActive,
      };
      updateClient(updatedClient);
    }
  };

  /**
   * to handle the input for boolean type
   */
  const handleCheckSms = (e) => {
    if (auth([ROLES.ANONYMOUS])) {
      toast.warning(i18next.t("anonymousTOAST"), {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        hideProgressBar: true,
        theme: "colored",
        style: {
          top: "50px",
        },
      });
    } else {
      setSmsActive(e.target.checked);
      const updatedClient = {
        smsActive: e.target.checked,
        emailActive: emailActive,
        mute: mute,
        clientActive: clientActive,
      };
      updateClient(updatedClient);
    }
  };

  /**
   * to handle the input for boolean type
   */
  const handleCheckMute = (e) => {
    if (auth([ROLES.ANONYMOUS])) {
      toast.warning(i18next.t("anonymousTOAST"), {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        hideProgressBar: true,
        theme: "colored",
        style: {
          top: "50px",
        },
      });
    } else {
      setMute(e.target.checked);
      const updatedClient = {
        mute: e.target.checked,
        emailActive: emailActive,
        smsActive: smsActive,
        clientActive: clientActive,
      };
      updateClient(updatedClient);
    }
  };

  /**
   * to handle the input for boolean type
   */
  const handleCheckClientActive = (e) => {
    if (auth([ROLES.OPERATOR])) {
      setClientActive(e.target.checked);
      const updatedClient = {
        clientActive: e.target.checked,
        emailActive: emailActive,
        smsActive: smsActive,
        mute: mute,
      };
      updateClient(updatedClient);
    }
  };

  /**
   *
   * @returns sending  SMS Test
   */
  const sendTestSMS = async () => {
    if (auth([ROLES.ANONYMOUS])) {
      toast.warning(i18next.t("anonymousTOAST"), {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        hideProgressBar: true,
        theme: "colored",
        style: {
          top: "50px",
        },
      });
    } else {
      if (numberTags.length === 0) {
        toast.error(i18next.t("msgnotsendsms"), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
          hideProgressBar: true,
          theme: "colored",
          style: {
            top: "50px",
          },
        });
        return;
      }

      try {
        const messageSMS = i18next.t("smstestmsg");
        const tag = `client: ${localStorage.getItem("clientPrefix")}`;

        const responses = await Promise.all(
          numberTags.map((number) =>
            sendSMSTestDeviceApi(number, messageSMS, tag)
          )
        );

        // Check if all responses are successful
        if (responses.every((response) => response?.statusCode !== 400)) {
          toast.info(i18next.t("msgsendsms"), {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
            hideProgressBar: true,
            theme: "colored",
            style: {
              top: "50px",
            },
          });
        } else {
          toast.error(i18next.t("msgnotsendsms"), {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
            hideProgressBar: true,
            theme: "colored",
            style: {
              top: "50px",
            },
          });
        }
      } catch (error) {
        console.error("error ", error);
        toast.error(i18next.t("msgnotsendsms"), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
          hideProgressBar: true,
          theme: "colored",
          style: {
            top: "50px",
          },
        });
      }
    }
  };
  /**
   * @returns Sending Mail Test
   */
  const sendTestMail = async () => {
    //console.log("emailTags", emailTags);
    if (auth([ROLES.ANONYMOUS])) {
      toast.warning(i18next.t("anonymousTOAST"), {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        hideProgressBar: true,
        theme: "colored",
        style: {
          top: "50px",
        },
      });
    } else {
      if (emailTags.length === 0) {
        toast.error(i18next.t("msgnotsendmail"), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
          hideProgressBar: true,
          theme: "colored",
          style: {
            top: "50px",
          },
        });
        return;
      }

      try {
        const fromMail = "vcm@okit.de";
        const subjectMail = "[OKIT.VCM]: E-Mail-Test";
        const messageMail = `
      Dies ist eine Test-E-Mail. 
      Wenn Sie diese E-Mail lesen können, ist Ihre E-Mail-Adresse korrekt hinterlegt.

      --
      Weitere Informationen unter: https://vcm.okit.de/
      `;
        const responses = await Promise.all(
          emailTags.map((email) =>
            sendMAILTestDeviceApi(email, fromMail, subjectMail, messageMail)
          )
        );

        // Check if all responses are successful
        if (responses.every((response) => response)) {
          toast.info(i18next.t("msgsendmail"), {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
            hideProgressBar: true,
            theme: "colored",
            style: {
              top: "50px",
            },
          });
        } else {
          toast.error(i18next.t("msgnotsendmail"), {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
            hideProgressBar: true,
            theme: "colored",
            style: {
              top: "50px",
            },
          });
        }
      } catch (error) {
        console.error("error ", error);
        toast.error(i18next.t("msgnotsendmail"), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
          hideProgressBar: true,
          theme: "colored",
          style: {
            top: "50px",
          },
        });
      }
    }
  };
  return (
    <>
      {isLoading && <Loading />}

      <MDBContainer
        fluid
        style={{ paddingLeft: "0", paddingRight: "0", marginTop: "-50px" }}
      >
        <MDBRow className="d-flex justify-content-center align-items-center">
          <MDBCol lg="8" style={{ width: "100%" }}>
            <MDBCard className="my-5 rounded-3" style={{ border: "none" }}>
              <MDBCardBody
                className={windowSize[0] > 991 && "px-5"}
                style={{ marginTop: "0px !important" }}
              >
                <h3 className="mb-4 pb-2 pb-md-0 mb-md-5 px-md-2">
                  {i18next.t("ClientInfo")}{" "}
                </h3>
                <MDBRow>
                  <MDBCol md="8" className="mb-4">
                    <h6>
                      {i18next.t("Emails")}
                      <Tooltip
                        placement="top"
                        title={i18next.t("Emails_tooltip")}
                      >
                        <font
                          style={{
                            cursor: "pointer",
                          }}
                          size="3"
                        >
                          {" "}
                          <HelpIcon style={{ zoom: "0.6" }} />
                        </font>
                      </Tooltip>
                    </h6>
                    <TagsInput
                      value={emailTags}
                      onChange={handleEmailTagsChange}
                      placeHolder={i18next.t("enteremail")}
                    />
                    <em style={{ fontSize: "13px" }}>
                      {i18next.t("msgTagEmail")}{" "}
                    </em>
                    <br />
                    <Button
                      onClick={sendTestMail}
                      style={{
                        backgroundColor: "#8c8cf0",
                        color: "white",
                        border: "1px solid #0d6efd",
                        height: "25px",
                        width: "200px",
                        fontSize: "12px",
                        marginTop: "5px",
                      }}
                      className="mb-4"
                      size="large"
                    >
                      {i18next.t("Send_Test_Mail")}
                    </Button>
                  </MDBCol>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <MDBCol md="4" className="mb-4">
                    <h6>
                      {i18next.t("Activereport")}
                      <Tooltip
                        placement="top"
                        title={i18next.t("Activereport_tooltip")}
                      >
                        <font
                          style={{
                            cursor: "pointer",
                          }}
                          size="3"
                        >
                          {" "}
                          <HelpIcon style={{ zoom: "0.6" }} />
                        </font>
                      </Tooltip>
                    </h6>
                    <Switch checked={emailActive} onChange={handleCheckEmail} />
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol md="8" className="mb-4">
                    <h6>
                      {i18next.t("Phonenumbers")}

                      <Tooltip
                        placement="top"
                        title={i18next.t("Phonenumbers_tooltip")}
                      >
                        <font
                          style={{
                            cursor: "pointer",
                          }}
                          size="3"
                        >
                          {" "}
                          <HelpIcon style={{ zoom: "0.6" }} />
                        </font>
                      </Tooltip>
                    </h6>
                    <TagsInput
                      value={numberTags}
                      onChange={handleNumberTagsChange}
                      placeHolder={i18next.t("enterphone")}
                    />
                    <em style={{ fontSize: "13px" }}>
                      {i18next.t("msgTagPhone")}{" "}
                    </em>
                    <br />
                    <Button
                      onClick={sendTestSMS}
                      style={{
                        backgroundColor: "#8c8cf0",
                        color: "white",
                        border: "1px solid #0d6efd",
                        height: "25px",
                        width: "200px",
                        fontSize: "12px",
                        marginTop: "5px",
                      }}
                      className="mb-4"
                      size="large"
                    >
                      {i18next.t("Send_Test_SMS")}
                    </Button>
                  </MDBCol>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <MDBCol md="4" className="mb-4">
                    <h6>
                      {i18next.t("Activesms")}
                      <Tooltip
                        placement="top"
                        title={i18next.t("Activesms_tooltip")}
                      >
                        <font
                          style={{
                            cursor: "pointer",
                          }}
                          size="3"
                        >
                          {" "}
                          <HelpIcon style={{ zoom: "0.6" }} />
                        </font>
                      </Tooltip>
                    </h6>
                    <Switch checked={smsActive} onChange={handleCheckSms} />
                  </MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol md="4" className="mb-4">
                    <h6>{i18next.t("mute")} </h6>
                    <Switch checked={mute} onChange={handleCheckMute} />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol md="4" className="mb-4">
                    <h6>{i18next.t("Enableprofile")} </h6>
                    <Switch
                      checked={clientActive}
                      onChange={handleCheckClientActive}
                      disabled={!auth([ROLES.OPERATOR]) && true}
                    />
                  </MDBCol>
                </MDBRow>

                <br />
                <br />
                {/*<Button style={{ backgroundColor: '#8c8cf0', color: "white", border: "1px solid #0d6efd" }} className='mb-4' size='large'>{i18next.t('Update')} </Button>*/}
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
};
export default ClientInfo;
