/*
 * Project: OKIT.VCM
 *
 * Copyright 2023 by OKIT GmbH
 * All rights reserved.
 *
 * Diese Software ist urheberrechtlich geschützt.
 */
import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import "rc-tooltip/assets/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Table from "react-bootstrap/Table";
import Pagination from "react-bootstrap/Pagination";
import { iconAlertWarning } from "../../utils/iconAlert.js";
import DatePicker from "react-datepicker";
import "../../assets/css/react-datepicker.css";
import { getHistoryMeasurementApi } from "../../hooks/measurementApi.js";
import { convertDateToISOWithOffset } from "../analyticMap/FctAnalyticMap";
import { Form } from "react-bootstrap";
import i18next from "i18next";
/**
 * This component represents the the TirePopup info.
 *
 * @author hatem sfar
 *
 */
function HistoryWarnings({ isOpen, onClose, dataInfo, deviceId }) {
  const [show] = useState(isOpen);
  const [alerts, setAlerts] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState(
    new Date(new Date().setMonth(new Date().getMonth() - 1))
  );
  const [endDate, setEndDate] = useState(new Date());
  const [componentFilter, setComponentFilter] = useState("");
  const [typeFilter, setTypeFilter] = useState("");
  const [severityFilter, setSeverityFilter] = useState("");

  const [contentSearch, setContentSearch] = useState("");

  useEffect(() => {
    // Get history alerts for the last month */
    getMeasurement();
    //eslint-disable-next-line
  }, []);

  const handleFiltredDataApi = async () => {
    const position = "de.okit.automotive.device.Logbook";
    let isoDateStart = "";
    let isoDateEnd = "";
    let pageNumber = 1;
    let nextPage = true;
    let allMeasurements = [];
    let alerts = [];

    // convert the date to UTC
    isoDateStart = convertDateToISOWithOffset(startDate);
    if (endDate) {
      isoDateEnd = convertDateToISOWithOffset(endDate);
    } else {
      isoDateEnd = null;
    }

    //console.log("allCoordinates: ", allCoordinates)
    while (nextPage) {
      let response;
      try {
        response = await getHistoryMeasurementApi(
          deviceId?.id,
          position,
          isoDateStart,
          isoDateEnd,
          pageNumber
        );
        const data = response.data.measurements[0].measurements;
        allMeasurements = allMeasurements.concat(data);
        if (!response.data.nextPage) {
          nextPage = false;
        }
        pageNumber++;
        //console.log("new measurements: ", measurements)
      } catch (err) {
        if (err.response && err.response.status === 504) {
          console.error(
            "Gateway Time-out: The server took too long to respond."
          );
        }
        //console.log("err")
        nextPage = false;
        if (err?.response?.status === 404) {
          nextPage = false;
        }
      }
    }
    alerts = formatMeasurements(allMeasurements);
    // Set the alerts state with the processed data
    setAlerts(alerts);
  };

  /**
   * to get the last recent measureument for selected namespace and device
   */
  //eslint-disable-next-line
  const getMeasurement = async () => {
    const position = "de.okit.automotive.device.Logbook";
    let isoDateStart = "";
    let isoDateEnd = "";
    let pageNumber = 1;
    let nextPage = true;
    let allMeasurements = [];
    let alerts = [];

    // convert the date to UTC
    isoDateStart = getOneMonthBeforeToday();
    isoDateEnd = null;

    //console.log("allCoordinates: ", allCoordinates)
    while (nextPage) {
      let response;
      try {
        response = await getHistoryMeasurementApi(
          deviceId?.id,
          position,
          isoDateStart,
          isoDateEnd,
          pageNumber
        );
        const data = response.data.measurements[0].measurements;
        allMeasurements = allMeasurements.concat(data);
        if (!response.data.nextPage) {
          nextPage = false;
        }
        pageNumber++;
        //console.log("new measurements: ", measurements)
      } catch (err) {
        if (err.response && err.response.status === 504) {
          console.error(
            "Gateway Time-out: The server took too long to respond."
          );
        }
        //console.log("err")
        nextPage = false;
        if (err?.response?.status === 404) {
          nextPage = false;
        }
      }
    }
    // console.log("allMeasurements ", allMeasurements);
    // Convert content strings to JSON and set into alerts
    alerts = formatMeasurements(allMeasurements);

    //console.log("alerts ", alerts);

    // Set the alerts state with the processed data
    setAlerts(alerts);
  };

  /**
   *  New method to format measurements
   * @param {*} measurements
   * @returns
   */
  const formatMeasurements = (measurements) => {
    const alerts = []; // Initialize a new alerts array

    measurements.forEach((measurement) => {
      try {
        const parsedContent = JSON.parse(measurement.content); // Parse the JSON string

        // Format dateTime to "YYYY-MM-DD HH:mm"
        const formattedDateTime = formatTimestamp(parsedContent.dateTime);

        // Convert Severity and Component and Type to lowercase, remove underscores from Type
        const formattedSeverity = parsedContent.severity.toLowerCase();

        const formattedType = parsedContent.type
          .toLowerCase()
          .replace(/_/g, " ");
        const formattedComponent = parsedContent.component.toLowerCase();

        // Create a new object with the required format
        alerts.push({
          ...parsedContent,
          dateTime: formattedDateTime, // Update dateTime format
          severity: formattedSeverity, // Update Severity format
          component: formattedComponent, // Update Component format
          type: formattedType, // Update Type format
        });
      } catch (error) {
        console.error("Error parsing JSON content:", error);
        // Handle the error by pushing an error object to alerts
        alerts.push({
          error: "Invalid JSON format",
          originalContent: measurement.content,
        });
      }
    });

    return alerts; // Return the populated alerts array
  };
  // Function to get the start date as one month before the current date
  const getOneMonthBeforeToday = () => {
    const today = new Date();
    const oneMonthBefore = new Date(today.setMonth(today.getMonth() - 1));
    return oneMonthBefore;
  };

  // Filter alerts based on the selected filters and search input
  const filteredAlerts = alerts?.filter((alert) => {
    const componentMatch =
      componentFilter === "" || alert.component === componentFilter;
    const typeMatch = typeFilter === "" || alert.type === typeFilter;
    const severityMatch =
      severityFilter === "" || alert.severity === severityFilter;

    const contentMatch =
      contentSearch === "" ||
      alert.content.toLowerCase().includes(contentSearch.toLowerCase());

    return componentMatch && severityMatch && typeMatch && contentMatch;
  });

  const itemsPerPage = 8;

  // Calculate total pages
  const totalPages =
    filteredAlerts?.length > 0
      ? Math.ceil(filteredAlerts?.length / itemsPerPage)
      : 0;

  // Get current page data
  const currentAlerts = filteredAlerts?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Create a method to format the timestamp
  const formatTimestamp = (timestamp) => {
    return timestamp
      ? `${String(new Date(timestamp).getHours()).padStart(2, "0")}:${String(
          new Date(timestamp).getMinutes()
        ).padStart(2, "0")} ${String(new Date(timestamp).getDate()).padStart(
          2,
          "0"
        )}.${String(new Date(timestamp).getMonth() + 1).padStart(
          2,
          "0"
        )}.${new Date(timestamp).getFullYear()}`
      : "-";
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onClose}
        backdrop="static"
        keyboard={true}
        scrollable={true}
        footer={null}
        backdropClassName="backdrop_style"
        style={{ zIndex: 9999, marginTop: "50px", height: "100%" }}
        size={"xl"}
      >
        <Modal.Header closeButton style={{}}>
          <Modal.Title style={{ margin: 5 }}>
            <font size="5" style={{ color: "#545454" }}>
              {" "}
              {i18next.t("HistoryAlertTitle")}
              {deviceId?.name}
            </font>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-between mb-3">
            <div style={{ zIndex: 10 }}>
              <label>{i18next.t("From")}: </label>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                showTimeSelect
                dateFormat="HH:mm dd-MM-yyyy"
                timeFormat="HH:mm"
                className={"inputDate2"}
                timeIntervals={15}
                placeholderText="HH:mm dd-MM-yyyy"
                calendarStartDay={1}
              />
            </div>
            <div>
              <label>{i18next.t("To")}: </label>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                showTimeSelect
                dateFormat="HH:mm dd-MM-yyyy"
                timeFormat="HH:mm"
                className={"inputDate2"}
                timeIntervals={15}
                placeholderText="HH:mm dd-MM-yyyy"
                calendarStartDay={1}
              />
            </div>
            <div
              style={{
                position: "relative",
                top: "27px",
              }}
            >
              <button
                onClick={handleFiltredDataApi}
                style={{
                  backgroundColor: "#8c8cf0",
                  color: "white",
                  border: "1px solid #0d6efd",
                  borderRadius: 7,
                  width: "100px",
                  fontSize: "15px",
                }}
                className="mb-4"
                size="large"
              >
                {i18next.t("Filter")}
              </button>
            </div>
          </div>

          <Table striped bordered hover>
            <thead>
              {/* Row for filters */}
              <tr>
                <th></th>
                <th>
                  <Form.Group>
                    <Form.Control
                      as="select"
                      value={severityFilter}
                      onChange={(e) => setSeverityFilter(e.target.value)}
                    >
                      <option value="">All</option>
                      {Array.from(
                        new Set(alerts?.map((alert) => alert.severity))
                      ).map((type, index) => (
                        <option key={index} value={type}>
                          {type}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </th>
                <th>
                  <Form.Group>
                    <Form.Control
                      as="select"
                      value={componentFilter}
                      onChange={(e) => setComponentFilter(e.target.value)}
                    >
                      <option value="">All</option>
                      {Array.from(
                        new Set(alerts?.map((alert) => alert.component))
                      ).map((component, index) => (
                        <option key={index} value={component}>
                          {component}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </th>
                <th>
                  <Form.Group>
                    <Form.Control
                      as="select"
                      value={typeFilter}
                      onChange={(e) => setTypeFilter(e.target.value)}
                    >
                      <option value="">All</option>
                      {Array.from(
                        new Set(alerts?.map((alert) => alert.type))
                      ).map((type, index) => (
                        <option key={index} value={type}>
                          {type}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </th>
                <th>
                  <Form.Group>
                    <Form.Control
                      type="text"
                      placeholder={i18next.t("Search")}
                      value={contentSearch}
                      onChange={(e) => setContentSearch(e.target.value)}
                    />
                  </Form.Group>
                </th>
              </tr>
              <tr>
                <th>{i18next.t("Date/Time")} </th>
                <th>{i18next.t("Severity")} </th>
                <th>{i18next.t("Component")}</th>
                <th>{i18next.t("Type")}</th>
                <th>{i18next.t("Content")}</th>
              </tr>
            </thead>
            <tbody>
              {currentAlerts?.length > 0 ? (
                currentAlerts?.map((alert, index) => (
                  <tr key={index}>
                    <td>{alert.dateTime}</td>
                    <td>{alert.severity}</td>
                    <td>{alert.component}</td>
                    <td>{alert.type}</td>
                    <td>{alert.content}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan="7"
                    className="text-center"
                    style={{ backgroundColor: "white" }}
                  >
                    <img
                      src={iconAlertWarning}
                      alt="warning icon"
                      style={{ marginRight: "10px" }}
                    />
                    <br />
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          <Pagination className="custom-pagination">
            <Pagination.First onClick={() => handlePageChange(1)} />
            <Pagination.Prev
              onClick={() =>
                handlePageChange(currentPage > 1 ? currentPage - 1 : 1)
              }
            />
            {[...Array(totalPages).keys()].map((number) => (
              <Pagination.Item
                key={number + 1}
                active={number + 1 === currentPage}
                onClick={() => handlePageChange(number + 1)}
              >
                {number + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() =>
                handlePageChange(
                  currentPage < totalPages ? currentPage + 1 : totalPages
                )
              }
            />
            <Pagination.Last onClick={() => handlePageChange(totalPages)} />
          </Pagination>
        </Modal.Body>
        <Modal.Footer hidden={true}></Modal.Footer>
      </Modal>
    </>
  );
}

export default HistoryWarnings;
