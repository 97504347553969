/*
 * Project: OKIT.VCM
 *
 * Copyright 2023 by OKIT GmbH
 * All rights reserved.
 *
 * Diese Software ist urheberrechtlich geschützt.
 */
import React, { useEffect, useState } from "react";
import i18next from "i18next";
import TimeOutToast from "../../utils/TimeOutToast";
import { useParams } from "react-router-dom";
import { getDeviceByIdApi } from "../../hooks/deviceApi";
import "react-web-tabs/dist/react-web-tabs.css";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { ProSidebarProvider } from "react-pro-sidebar";
import SideBarTires from "../../utils/SideBarTires";
import AppSettingsAltOutlinedIcon from "@mui/icons-material/AppSettingsAltOutlined";
import TireRepairIcon from "@mui/icons-material/TireRepair";
import ScaleIcon from "@mui/icons-material/Scale";
import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";

/**
 * This component represents the the DeviceDetailsView
 * represent the dynamic tires wih thier infos.
 *
 * @author hatem sfar
 *
 */
function DeviceDetailView() {
  const [devices, setDevices] = useState([]);
  const { id } = useParams();

  const children = [
    {
      name: i18next.t("VehicleManagement"),
      icon: <FeedOutlinedIcon />,
      component: "VehicleManagement",
    },
    {
      name: i18next.t("OverView"),
      icon: <TireRepairIcon />,
      component: "OverView",
    },
    {
      name: i18next.t("TireWeight"),
      icon: <ScaleIcon />,
      component: "TireWeight",
    },
    {
      name: i18next.t("DriverApp"),
      icon: <AppSettingsAltOutlinedIcon />,
      component: "DriverApp",
    },
    {
      name: i18next.t("Properties"),
      icon: <SettingsOutlinedIcon />,
      component: "Properties",
    },
  ];

  useEffect(
    () => {
      getDeviceByIdApi(id)
        .then((response) => {
          setDevices(response);
        })
        .catch((err) => {
          if (err.response && err.response.status === 504) {
            console.error(
              "Gateway Time-out: The server took too long to respond."
            );
            TimeOutToast();
          }
          console.log(err);
        });
      //eslint-disable-next-line
    },
    //eslint-disable-next-line
    [
      /*show*/
    ]
  );

  return (
    <>
      <ProSidebarProvider>
        <SideBarTires children={children} device={devices} />
      </ProSidebarProvider>
    </>
  );
}
export default DeviceDetailView;
