/*
 * Project: OKIT.VCM
 *
 * Copyright 2023 by OKIT GmbH
 * All rights reserved.
 *
 * Diese Software ist urheberrechtlich geschützt.
 */
import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import TimeOutToast from "../../utils/TimeOutToast";
import { Input } from "antd";
import {
  getMeasurementByNameSpaceApi,
  setMeasurementByNameSpaceApi,
} from "../../hooks/measurementApi";
import QRCode from "qrcode.react";
import useWindowSize from "../../utils/useWindowSize";
import { toast } from "react-toastify";
import { sendSMSTestDeviceApi } from "../../hooks/deviceApi";
import lockerON from "../../assets/images/lockerON.svg";
import lockerOff from "../../assets/images/lockerOff.svg";
import auth from "../../auth/auth";
import ROLES from "../../utils/roles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import config from "../../config";

/**
 * This component represents the the VehicleManagement
 *
 *
 * @author hatem sfar
 *
 */
export default function DriverApp({ device }) {
  const [pin, setPin] = useState();
  // eslint-disable-next-line
  const [token, setToken] = useState();
  const [qrText, setQrText] = useState("");
  const windowSize = useWindowSize();
  const [driverNumber, setDriverNumber] = useState();
  const [deviceMacAddress, setDeviceMacAddress] = useState();
  const [isDeviceMacAddressExpired, setIsDeviceMacAddressExpired] = useState();
  const [installedAppVersion, setInstalledAppVersion] = useState();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    getMeasurement();
    // eslint-disable-next-line
  }, [device]);

  /**
   * to handle the input in the field
   * @param {*} e
   */
  const handleInputChangeDriverNumber = (e) => {
    const newValue = e.target.value;
    if (driverNumber !== newValue) {
      setDriverNumber(newValue);
    }
  };

  /**
   * updateMeasurement
   */
  const updateMeasurement = async () => {
    const validTags =
      driverNumber?.startsWith("0049") || driverNumber?.startsWith("+49");

    // If there are any invalid tags, show the toast error
    if (auth([ROLES.ANONYMOUS])) {
      toast.warning(i18next.t("anonymousTOAST"), {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        hideProgressBar: true,
        theme: "colored",
        style: {
          top: "50px",
        },
      });
      setDriverNumber(null);
    } else if (!validTags) {
      toast.warning(i18next.t("wrongnumberformat"), {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        hideProgressBar: true,
        theme: "colored",
        style: {
          top: "50px",
        },
      });
      setDriverNumber(null);
    } else {
      const namespace = "de.okit.automotive.device.DriverMobileNumber";
      try {
        setMeasurementByNameSpaceApi(device.id, driverNumber, namespace).then(
          () => {
            getMeasurement();
          }
        );
      } catch (err) {
        if (err.response && err.response.status === 504) {
          console.error(
            "Gateway Time-out: The server took too long to respond."
          );
          TimeOutToast();
        }
        console.log(err);
      }
    }
  };

  /**
   * handleInputChangeDeviceMacAddress
   * @param {*} e
   */
  const handleInputChangeDeviceMacAddress = (e) => {
    if (auth([ROLES.ANONYMOUS])) {
      toast.warning(i18next.t("anonymousTOAST"), {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        hideProgressBar: true,
        theme: "colored",
        style: {
          top: "50px",
        },
      });
    } else {
      const newValue = e.target.value;
      if (deviceMacAddress !== newValue) {
        setDeviceMacAddress(newValue);
      }
    }
  };

  /**
   * updateMeasurementDeviceMacAddress
   */
  const updateMeasurementDeviceMacAddress = async () => {
    if (auth([ROLES.ANONYMOUS])) {
      toast.warning(i18next.t("anonymousTOAST"), {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        hideProgressBar: true,
        theme: "colored",
        style: {
          top: "50px",
        },
      });
    } else {
      const namespace = "de.okit.automotive.device.BluetoothMacAddress";
      try {
        setMeasurementByNameSpaceApi(
          device.id,
          deviceMacAddress,
          namespace
        ).then(() => {
          getMeasurement();
        });
      } catch (err) {
        if (err.response && err.response.status === 504) {
          console.error(
            "Gateway Time-out: The server took too long to respond."
          );
          TimeOutToast();
        }
        console.log(err);
      }
    }
  };

  /**
   * resetMeasurementDeviceMacAddress
   */
  const resetMeasurementDeviceMacAddress = async () => {
    if (auth([ROLES.ANONYMOUS])) {
      toast.warning(i18next.t("anonymousTOAST"), {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        hideProgressBar: true,
        theme: "colored",
        style: {
          top: "50px",
        },
      });
    } else {
      const namespace = "de.okit.automotive.device.BluetoothMacAddress";
      try {
        setMeasurementByNameSpaceApi(device.id, "", namespace).then(() => {
          getMeasurement();
        });
      } catch (err) {
        if (err.response && err.response.status === 504) {
          console.error(
            "Gateway Time-out: The server took too long to respond."
          );
          TimeOutToast();
        }
        console.log(err);
      }
    }
  };

  /**
   *
   */
  const getMeasurement = async () => {
    const position =
      "de.okit.automotive.device.PIN" +
      "," +
      "de.okit.automotive.device.TOKEN" +
      "," +
      "de.okit.automotive.device.DriverMobileNumber" +
      "," +
      "de.okit.automotive.device.BluetoothMacAddress" +
      "," +
      "de.okit.automotive.device.InstalledAppVersion";

    getMeasurementByNameSpaceApi(device.id, position)
      .then((response) => {
        response &&
          response?.forEach((info) => {
            if (info && info.namespace.endsWith("PIN")) {
              if (info.measurements && info.measurements.length > 0) {
                const pinMeasurement = info.measurements[0];
                const pinValue = pinMeasurement.content;
                const measurementDate = new Date(
                  pinMeasurement.measurementdate
                );
                const currentTime = new Date();

                // Calculate the difference in milliseconds
                const timeDiff = currentTime - measurementDate;

                // Convert the difference from milliseconds to hours
                const timeDiffInHours = timeDiff / (1000 * 60 * 60);

                if (timeDiffInHours > 24) {
                  // Measurement is older than 24 hours, set PIN to null
                  setPin(null);
                } else {
                  // Measurement is recent, set the PIN value
                  setPin(pinValue);
                  const qrCodeText = generateQRCode(pinValue);
                  setQrText(qrCodeText);
                }
              }
            }
            if (info && info.namespace.endsWith("TOKEN")) {
              if (info.measurements && info.measurements.length > 0) {
                setToken(info?.measurements[0]?.content);
              }
            }
            if (info && info.namespace.endsWith("DriverMobileNumber")) {
              if (info.measurements && info.measurements.length > 0) {
                setDriverNumber(info?.measurements[0]?.content);
              }
            }
            if (info && info.namespace.endsWith("BluetoothMacAddress")) {
              if (info.measurements && info.measurements.length > 0) {
                const macAddressMeasurement = info?.measurements[0];
                const macAddressValue = macAddressMeasurement?.content;

                setDeviceMacAddress(macAddressValue);

                const measurementDate = new Date(
                  macAddressMeasurement?.measurementdate
                );
                const currentTime = new Date();
                // Calculate the difference in milliseconds
                const timeDiff = currentTime - measurementDate;

                // Convert the difference from milliseconds to hours
                const timeDiffInHours = timeDiff / (1000 * 60 * 60);

                if (timeDiffInHours > 24) {
                  setIsDeviceMacAddressExpired(true);
                } else {
                  setIsDeviceMacAddressExpired(false);
                }
              }
            }
            if (info && info.namespace.endsWith("InstalledAppVersion")) {
              if (info.measurements && info.measurements.length > 0) {
                setInstalledAppVersion(info?.measurements[0]?.content);
              }
            }
          });
      })
      .catch((error) => {
        if (error.response && error.response.status === 504) {
          console.error(
            "Gateway Time-out: The server took too long to respond."
          );
          TimeOutToast();
        }
      });
  };

  /**
   *
   */
  const generateNewPin = () => {
    const pin = Math.floor(100000 + Math.random() * 900000);
    const namespace = "de.okit.automotive.device.PIN";
    try {
      setMeasurementByNameSpaceApi(device.id, pin, namespace).then(() => {
        getMeasurement();
      });
    } catch (err) {
      if (err.response && err.response.status === 504) {
        console.error("Gateway Time-out: The server took too long to respond.");
        TimeOutToast();
      }
      console.log(err);
    }
  };

  /**
   *
   */
  const generateNewToken = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const length = 32; // Adjust the length of the token as needed
    let token = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      token += characters[randomIndex];
    }
    //console.log("New Token:", token);
    const namespace = "de.okit.automotive.device.TOKEN";
    try {
      setMeasurementByNameSpaceApi(device.id, token, namespace).then(() => {
        getMeasurement();
      });
    } catch (err) {
      if (err.response && err.response.status === 504) {
        console.error("Gateway Time-out: The server took too long to respond.");
        TimeOutToast();
      }
      console.log(err);
    }
  };

  /**
   *
   */
  const generateQRCode = (pinValue) => {
    //const appLink = 'https://play.google.com/store/apps/details?id=com.activision.callofduty.warzone&hl=en&gl=US'; // Replace this with your actual app link
    const qrText = `deviceID=${device.id}&PIN=${
      pinValue != null ? pinValue : pin
    }`;
    return qrText;
  };
  /**
   *
   */
  const handleGenerateQRCode = () => {
    if (!pin) {
      generateNewPin();
    }

    const qrCodeText = generateQRCode();
    setQrText(qrCodeText);
  };

  /**
   *
   */
  const handleRevokeAllDevices = () => {
    if (pin) {
      setIsDialogOpen(true);
    } else {
      setIsDialogOpen(true);
      generateNewToken();
    }
  };

  const handleConfirmRevoke = () => {
    generateNewPin();
    generateNewToken();
    setIsDialogOpen(false); // Close the dialog
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false); // Close the dialog without action
  };

  const sendTestSMS = () => {
    const validTags =
      driverNumber?.startsWith("0049") || driverNumber?.startsWith("+49");

    if (auth([ROLES.ANONYMOUS])) {
      toast.warning(i18next.t("anonymousTOAST"), {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        hideProgressBar: true,
        theme: "colored",
        style: {
          top: "50px",
        },
      });
      setDriverNumber(null);
    } else if (!validTags) {
      toast.error(i18next.t("msgnotsendsms"), {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        hideProgressBar: true,
        theme: "colored",
        style: {
          top: "50px",
        },
      });
    } else {
      const messageSMS = i18next.t("smstestmsg");
      const tag = `Deviceid: ${device.id}`;
      sendSMSTestDeviceApi(driverNumber, messageSMS, tag)
        .then((response) => {
          if (response?.statusCode !== 400) {
            toast.info(i18next.t("msgsendsms"), {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000,
              hideProgressBar: true,
              theme: "colored",
              style: {
                top: "50px",
              },
            });
          } else {
            toast.error(i18next.t("msgnotsendsms"), {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000,
              hideProgressBar: true,
              theme: "colored",
              style: {
                top: "50px",
              },
            });
          }
        })
        .catch((error) => {
          console.error("error ", error);
        });
    }
  };

  return (
    <Container
      fluid
      style={{ width: windowSize[0] > 991 ? "50%" : "100%", float: "left" }}
    >
      <h3
        style={{ marginLeft: "15px", marginTop: "10px" }}
        className="mb-4 pb-2 pb-md-0 mb-md-5 px-md-2"
      >
        {i18next.t("DriverApp")}{" "}
      </h3>

      <Row style={{ marginTop: "10px" }}>
        <Col>
          <font size="3">{i18next.t("AppVersion")}</font>
        </Col>
        <Col>
          <font
            style={{
              width: "210px",
              borderRadius: "8px",
              borderColor: "#cdcbcb",
            }}
          >
            {installedAppVersion === config.MOBILE_APP_VERSION ? (
              i18next.t("AppVersionTextNoUpdate")
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "max-content",
                }}
              >
                {i18next.t("AppVersionTextUpdate")}
                <Link to={config.MOBILE_APP_VERSION_URL}>
                  <div
                    style={{
                      cursor: "pointer",
                      marginLeft: "8px", // Add some left margin to create space
                    }}
                  >
                    {config.MOBILE_APP_VERSION}
                  </div>
                </Link>
              </div>
            )}
          </font>
        </Col>
      </Row>
      <Row style={{ marginTop: "10px" }}>
        <Col>
          <font size="3">{i18next.t("DriverNumber")}</font>
        </Col>

        <Col>
          <div style={{ position: "relative", width: "210px" }}>
            <Input
              style={{
                width: "210px",
                borderRadius: "8px",
                borderColor: "#cdcbcb",
              }}
              onChange={handleInputChangeDriverNumber}
              onBlur={updateMeasurement}
              type="text"
              value={driverNumber}
            />
            <Button
              style={{
                position: "absolute",
                right: "-35px",
                top: "50%",
                transform: "translateY(-50%)",
                cursor: "pointer",
                backgroundColor: "#8c8cf0",
                padding: "5px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={sendTestSMS}
            >
              <img
                alt="Send SMS"
                src={require("../../assets/images/sendTestSMS.png")}
                height="20"
              />
            </Button>
          </div>
        </Col>
      </Row>

      <Row style={{ marginTop: "10px" }}>
        <Col>
          <font size="3">{i18next.t("DeviceMacAddress")}</font>
        </Col>

        <Col>
          <div style={{ position: "relative", width: "210px" }}>
            <Input
              style={{
                width: "100%", // Full width of the container
                borderRadius: "8px",
                borderColor: "#cdcbcb",
                paddingRight: "30px", // Add space for the "X" button
              }}
              onChange={handleInputChangeDeviceMacAddress}
              onBlur={updateMeasurementDeviceMacAddress}
              type="text"
              value={deviceMacAddress}
            />

            {isDeviceMacAddressExpired ? (
              <Button
                style={{
                  position: "absolute",
                  right: "-35px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                  backgroundColor: "#8c8cf0",
                  padding: "5px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={updateMeasurementDeviceMacAddress}
              >
                <img alt="" src={lockerON} height="20" />
              </Button>
            ) : (
              <span
                style={{
                  position: "absolute",
                  right: "-30px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  color: "#999",
                }}
              >
                <img alt="" src={lockerOff} height="20" />
              </span>
            )}

            {deviceMacAddress && (
              <span
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                  color: "#999",
                }}
                onClick={resetMeasurementDeviceMacAddress}
              >
                &#10006;
              </span>
            )}
          </div>
        </Col>
      </Row>

      <Row style={{ marginTop: "10px" }}>
        <Col>
          <font size="3">{i18next.t("DeviceID")}</font>
        </Col>
        <Col>
          <font
            style={{
              width: "210px",
              borderRadius: "8px",
              borderColor: "#cdcbcb",
            }}
          >
            {device.id}{" "}
          </font>
        </Col>
      </Row>
      <Row style={{ marginTop: "10px" }}>
        <Col>
          <font size="3">{i18next.t("pin")}</font>
        </Col>
        <Col>
          <font
            style={{
              width: "210px",
              borderRadius: "8px",
              borderColor: "#cdcbcb",
            }}
          >
            {pin}
          </font>
          {/*
                    <Link >
                        <font size="3" onClick={generateNewPin}>{i18next.t('newPin')}</font>
                    </Link>
                */}
        </Col>
      </Row>

      <Row style={{ marginTop: "20px" }}>
        <Col>
          <Button
            onClick={handleGenerateQRCode}
            style={{ backgroundColor: "#8c8cf0" }}
          >
            {i18next.t("invitaion")}
          </Button>
          <br />
          <br />
          <Button
            onClick={handleRevokeAllDevices}
            style={{ backgroundColor: "#8c8cf0" }}
          >
            {i18next.t("revokealldevices")}
          </Button>
        </Col>
        <Col>{qrText && pin && <QRCode value={qrText} />}</Col>
      </Row>

      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>{i18next.t("ConfirmRevokeText")}</DialogTitle>
        <DialogContent>
          <DialogContentText>{i18next.t("revokePopUpText")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            style={{ backgroundColor: "gray" }}
          >
            {i18next.t("Cancel")}
          </Button>
          <Button
            onClick={handleConfirmRevoke}
            style={{ backgroundColor: "#8c8cf0" }}
            autoFocus
          >
            {i18next.t("Confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
