import { useEffect } from "react";
import { useWebSocket } from "../components/webSocket/WebSocketContext";
import { getDeviceByIdApi } from "../hooks/deviceApi";

export function ChangeDataWS({
  setDevices,
  setCoordinates,
  devices,
  setDeviceCoordinate,
}) {
  const webSocketManager = useWebSocket();

  useEffect(() => {
    const handleWebSocketMessage = (event) => {
      const data = JSON.parse(event.data);
      //console.log("data: ", data);
      if (data.client === localStorage.getItem("clientPrefix")) {
        if (data.type === "device") {
          const newDevice = { ...data, alert: "noaxle" }; // Add the alert property with the value "error"
          setDevices((prevDevices) => {
            if (!prevDevices) {
              return [];
            }

            return [...prevDevices, newDevice];
          });
        } else if (data.type === "device_delete") {
          setDevices((prevDevices) => {
            if (!prevDevices) {
              // Handle the case where prevDevices is undefined or null
              // For example, you can return an empty array or some default value
              return [];
            }
            return prevDevices.filter((device) => device.id !== data.id);
          });
        } else if (data.type === "measurement") {
          if (
            data.node.includes("Pressure") ||
            data.node.includes("Temperature")
          ) {
            getDeviceByIdApi(data.device)
              .then((response) => {
                const updatedDevice = response;
                setDevices((prevDevices) => {
                  if (!prevDevices) {
                    // Handle the case where prevDevices is undefined or null
                    // For example, you can return an empty array or some default value
                    return [];
                  }
                  // Find the index of the existing device with the same id
                  const index = prevDevices.findIndex(
                    (device) => device.id === updatedDevice.id
                  );
                  if (index !== -1) {
                    // Replace the existing device with the updated alert device
                    const newDevices = [...prevDevices];
                    newDevices[index] = updatedDevice;
                    return newDevices;
                  }
                });
                // Update the alert property of the coordinates for the specified device ID
                setCoordinates((prevCoordinates) => {
                  if (!prevCoordinates) {
                    // Handle the case where prevDevices is undefined or null
                    // For example, you can return an empty array or some default value
                    return [];
                  }
                  const newCoordinates = [...prevCoordinates];
                  const deviceCoordinates = newCoordinates.filter(
                    (coordinate) => coordinate.device === updatedDevice.id
                  );
                  deviceCoordinates.forEach((coordinate) => {
                    coordinate.alert = updatedDevice.alert;
                  });
                  return newCoordinates;
                });
              })
              .catch((err) => {
                console.log(err);
              });
          }
        } else if (data.type === "coordinate") {
          const newCoordinate = data;
          setCoordinates((prevCoordinates) => {
            if (!prevCoordinates) {
              // Handle the case where prevDevices is undefined or null
              // For example, you can return an empty array or some default value
              return [];
            }
            const index = prevCoordinates.findIndex(
              (coordinate) => coordinate.device === newCoordinate.device
            );
            if (index !== -1) {
              // Replace the existing coordinate with the new coordinate
              const updatedCoordinates = [...prevCoordinates];
              updatedCoordinates[index] = newCoordinate;

              // Find the device with the same ID as the coordinate
              const device = devices.find(
                (device) => device.id === newCoordinate.device
              );

              if (device) {
                // If the device is found, update the coordinate's alert
                updatedCoordinates[index].alert = device.alert;
                updatedCoordinates[index].name = device.name;
              }

              return updatedCoordinates;
            } else {
              // Add the new coordinate as a new entry in the list
              const coordinateWithAlert = { ...newCoordinate };
              return [...prevCoordinates, coordinateWithAlert];
            }
          });
          setDeviceCoordinate((prevCoordinates) => {
            if (!prevCoordinates) {
              // Handle the case where prevDevices is undefined or null
              // For example, you can return an empty array or some default value
              return [];
            }
            const index = prevCoordinates.findIndex(
              (coordinate) => coordinate.id === newCoordinate.device
            );

            if (index !== -1) {
              // Replace the existing coordinate with the new coordinate
              const updatedCoordinates = [...prevCoordinates];
              updatedCoordinates[index].coordinate = newCoordinate;

              return updatedCoordinates;
            }
          });
        }
      }
    };
    webSocketManager.setOnMessageCallback(handleWebSocketMessage);

    return () => {
      webSocketManager.removeOnMessageCallback(handleWebSocketMessage);
    };
    //eslint-disable-next-line
  }, [webSocketManager]);
}

export function ChangeDataTires({
  tires,
  setTires,
  generateAxleWeightList,
  device,
}) {
  const webSocketManager = useWebSocket();
  //console.log("start data change");

  useEffect(() => {
    const handleWebSocketMessage = (event) => {
      const data = JSON.parse(event.data);

      if (
        data.client === localStorage.getItem("clientPrefix") &&
        data.type === "measurement" &&
        device.id === data.device
      ) {
        //console.log("data ", data);
        const namespaceParts = data.node.split(".");
        let axle = "";
        let direction = "";
        let position = "";
        const namespaceName = namespaceParts[namespaceParts.length - 1];

        if (namespaceName === "LoadMeasured") {
          axle = parseInt(namespaceParts[4], 10);
          direction = namespaceParts[5];
          position = parseInt(namespaceParts[6], 10);
          // Update the tires array
          const updatedTires = tires.map((tire) => {
            if (
              tire.axle === axle &&
              tire.direction === direction &&
              tire.position === position
            ) {
              // Update the weight with the content value from WebSocket data
              return {
                ...tire,
                Weight: Math.round(parseFloat(data.content)),
              };
            }
            return tire;
          });

          //console.log("updatedTires", updatedTires);
          setTires(updatedTires);
          generateAxleWeightList !== null &&
            generateAxleWeightList(updatedTires);
        }
        if (namespaceName === "Pressure") {
          axle = parseInt(namespaceParts[5], 10);
          direction = namespaceParts[6];
          position = parseInt(namespaceParts[7], 10);
          // Update the tires array
          const updatedTires = tires.map((tire) => {
            if (
              tire.axle === axle &&
              tire.direction === direction &&
              tire.position === position
            ) {
              // Update the Pressure with the content value from WebSocket data
              return {
                ...tire,
                Pressure: data.content,
              };
            }
            return tire;
          });
          //console.log("data ", data);
          //console.log("updatedTires ", updatedTires);
          setTires(updatedTires);
        }
        if (namespaceName === "Temperature") {
          axle = parseInt(namespaceParts[5], 10);
          direction = namespaceParts[6];
          position = parseInt(namespaceParts[7], 10);
          // Update the tires array
          const updatedTires = tires.map((tire) => {
            if (
              tire.axle === axle &&
              tire.direction === direction &&
              tire.position === position
            ) {
              // Update the Temperature with the content value from WebSocket data
              return {
                ...tire,
                Temperature: data.content,
              };
            }
            return tire;
          });
          setTires(updatedTires);
        }
      }
    };
    webSocketManager.setOnMessageCallback(handleWebSocketMessage);

    return () => {
      webSocketManager.removeOnMessageCallback(handleWebSocketMessage);
    };
    //eslint-disable-next-line
  }, [webSocketManager, tires, setTires]);
}
