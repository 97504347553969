/*
 * Project: OKIT.VCM
 *
 * Copyright 2023 by OKIT GmbH
 * All rights reserved.
 *
 * Diese Software ist urheberrechtlich geschützt.
 */
import React from "react";
import { EditControl } from "react-leaflet-draw";
import "../../assets/css/leaflet.draw.css";

/**
 * This component represents AnalyticMap
 *
 * @author hatem sfar
 *
 */
const EditControlGeofence = ({ setGeofencingPolygons, onEdited }) => {

  
  const onCreated = (e) => {
    const layer = e.layer;
    const latlngs = layer
      .getLatLngs()[0]
      .map((latlng) => [latlng.lat, latlng.lng]);
    const newPolygon = {
      id: layer._leaflet_id,
      latlngs: latlngs,
      type: "create",
    };
    setGeofencingPolygons((polygons) => [...polygons, newPolygon]);
  };

  const onEditedHandler = (layers) => {
    const changedPolys = Object.values(layers._layers).map((layer) => {
      const latlngs = layer
        .getLatLngs()[0]
        .map((latlng) => [latlng.lat, latlng.lng]);
      return { id: layer.options.id, latlngs: latlngs, type: "edit" };
    });

    setGeofencingPolygons((polygons) => {
      const updatedPolygons = polygons.map((polygon) => {
        const updated = changedPolys.find(
          (changedPoly) => changedPoly.id === polygon.id
        );
        return updated || polygon;
      });
      return updatedPolygons;
    });

    if (onEdited) {
      onEdited(layers); // Call the onEdited prop to update parent state
    }
  };

  const onDeleted = (layers) => {
    const deletedIds = Object.values(layers._layers).map(
      (layer) => layer.options.id
    );

    setGeofencingPolygons((polygons) => {
      const updatedPolygons = polygons.filter(
        (polygon) => !deletedIds.includes(polygon.id)
      );
      return updatedPolygons;
    });
  };

  return (
    <EditControl
      position="topright"
      onCreated={onCreated}
      onEdited={(e) => onEditedHandler(e.layers)}
      onDeleted={(e) => onDeleted(e.layers)}
      draw={{
        marker: false,
        circlemarker: false,
        polyline: false,
        rectangle: false,
        circle: false,
        polygon: true,
      }}
    />
  );
};

export default EditControlGeofence;
