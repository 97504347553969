/*
 * Project: OKIT.VCM
 *
 * Copyright 2023 by OKIT GmbH
 * All rights reserved.
 *
 * Diese Software ist urheberrechtlich geschützt.
 */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import i18next from "i18next";
import { Link } from "react-router-dom";
import TirePopUp from "./TirePopUp";
import "../../assets/css/deviceDetailsView.css";
import useWindowSize from "../../utils/useWindowSize";
import {
  getMeasurementByNameSpaceApi,
  getTiresMeasurementApi,
} from "../../hooks/measurementApi";
import { ChangeDataTires } from "../../utils/changeDataWS";
import TimeOutToast from "../../utils/TimeOutToast.js";
import HistoryWarnings from "./HistoryWarnings.js";
import { tyreColor, tyreLogo } from "../../utils/deviceState.js";

/**
 * This component represents the the DeviceDetailsView
 * represent the dynamic tires wih thier infos.
 *
 * @author hatem sfar
 *
 */
export default function OverView({ device }) {
  const [tires, setTires] = useState([]);
  const [show, setShow] = useState(false);
  const { id } = useParams();
  const windowSize = useWindowSize();
  const [isLoading, setIsLoading] = useState(true);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isHistoryPopupOpen, setHistoryIsPopupOpen] = useState(false);
  const [dataInfo, setDataInfo] = useState(null);

  /**
   *
   */
  useEffect(() => {
    getTires();
    //eslint-disable-next-line
  }, []);

  /**
   * to handle the popup of the tire
   * @param {*} p
   */
  const handleOpenPopup = (p) => {
    setDataInfo(p);
    setIsPopupOpen(true);
  };

  /**
   * to close the popup
   */
  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  /**
   * to get the tires of the selected device
   */
  const getTires = () => {
    getTiresMeasurementApi(id)
      .then((response) => {
        if (response.error) {
          setTires([]);
        } else {
          // Transform data
          const transformedData = response?.measurements?.flatMap(
            (measurement) =>
              measurement.content.split(",").map((val) => {
                const [var1, axle, direction, position] = val.split(".");
                return {
                  var1,
                  axle: Number(axle),
                  direction,
                  position: Number(position),
                };
              })
          );

          // Map over transformedData array and make axios call for each position
          const axiosCalls = transformedData?.map(async (position) => {
            const position_namespace =
              "de.okit.automotive.tpms." +
              position.var1 +
              "." +
              position.axle +
              "." +
              position.direction +
              "." +
              position.position +
              ".*" +
              "," +
              "de.okit.automotive.tire." +
              position.var1 +
              "." +
              position.axle +
              "." +
              position.direction +
              "." +
              position.position +
              ".*" +
              "," +
              "de.okit.automotive.tire." +
              position.var1 +
              "." +
              position.axle +
              ".Trailer" +
              "," +
              "de.okit.automotive.tire." +
              position.var1 +
              "." +
              position.axle +
              ".Steering";
            return await getMeasurementByNameSpaceApi(id, position_namespace)
              .then((response) => {
                response &&
                  response?.forEach((data) => {
                    if (data && data.namespace.endsWith("Pressure")) {
                      if (data.measurements && data.measurements.length > 0) {
                        position.Pressure = data?.measurements[0]?.content;
                        position.Timestamp_Pressure =
                          data?.measurements[0]?.measurementdate;
                      }
                    }
                    if (data && data.namespace.endsWith("Temperature")) {
                      if (data.measurements && data.measurements.length > 0) {
                        position.Temperature = data?.measurements[0]?.content;
                        position.Timestamp_Temperture =
                          data?.measurements[0]?.measurementdate;
                      }
                    }
                    if (data && data.namespace.endsWith("TemperatureAlarm")) {
                      if (data.measurements && data.measurements.length > 0) {
                        position.TemperatureAlarm =
                          data?.measurements[0]?.content;
                        position.Timestamp_TemperatureAlarm =
                          data?.measurements[0]?.measurementdate;
                      }
                    }

                    if (data && data.namespace.endsWith("TemperatureWarn")) {
                      if (data.measurements && data.measurements.length > 0) {
                        position.TemperatureWarn =
                          data?.measurements[0]?.content;
                        position.Timestamp_TemperatureWarn =
                          data?.measurements[0]?.measurementdate;
                      }
                    }
                    if (data && data.namespace.endsWith("PressureLowerWarn")) {
                      if (data.measurements && data.measurements.length > 0) {
                        position.PressureLowerWarn =
                          data?.measurements[0]?.content;
                        position.Timestamp_PressureLowerWarn =
                          data?.measurements[0]?.measurementdate;
                      }
                    }
                    if (data && data.namespace.endsWith("PressureLowerAlarm")) {
                      if (data.measurements && data.measurements.length > 0) {
                        position.PressureLowerAlarm =
                          data?.measurements[0]?.content;
                        position.Timestamp_PressureLowerAlarm =
                          data?.measurements[0]?.measurementdate;
                      }
                    }
                    if (data && data.namespace.endsWith("PressureUpperWarn")) {
                      if (data.measurements && data.measurements.length > 0) {
                        position.PressureUpperWarn =
                          data?.measurements[0]?.content;
                        position.Timestamp_PressureUpperWarn =
                          data?.measurements[0]?.measurementdate;
                      }
                    }
                    if (data && data.namespace.endsWith("PressureUpperAlarm")) {
                      if (data.measurements && data.measurements.length > 0) {
                        position.PressureUpperAlarm =
                          data?.measurements[0]?.content;
                        position.Timestamp_PressureUpperAlarm =
                          data?.measurements[0]?.measurementdate;
                      }
                    }
                    if (data && data.namespace.endsWith("Steering")) {
                      if (data.measurements && data.measurements.length > 0) {
                        const stringValue = data?.measurements[0]?.content;
                        const booleanValue = stringValue === "true";
                        position.Steering = Boolean(booleanValue);
                        position.Timestamp_Steering =
                          data.measurements &&
                          data?.measurements[0]?.measurementdate;
                      }
                    }
                    if (data && data.namespace.endsWith("Trailer")) {
                      if (data.measurements && data.measurements.length > 0) {
                        const stringValue = data?.measurements[0]?.content;
                        const booleanValue = stringValue === "true";
                        position.Trailer = Boolean(booleanValue);
                        position.Timestamp_Trailer =
                          data.measurements &&
                          data?.measurements[0]?.measurementdate;
                      }
                    }
                  });
              })
              .catch((error) => {
                if (error.response && error.response.status === 504) {
                  console.error(
                    "Gateway Time-out: The server took too long to respond."
                  );
                  TimeOutToast();
                  setIsLoading(false);
                }
              });
          });

          if (!axiosCalls) {
            setShow(true);
            setIsLoading(false);
          }
          // Wait for all axios calls to complete using Promise.all()
          axiosCalls &&
            Promise.all(axiosCalls).then(() => {
              setTires(transformedData ? transformedData : []);
              setShow(true);
              setIsLoading(false);
            });
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 504) {
          console.error(
            "Gateway Time-out: The server took too long to respond."
          );
          TimeOutToast();
          setIsLoading(false);
        }
        console.log(err);
        setShow(true);
        setIsLoading(false);
      });
  };

  const lineStyle = {
    gridColumn: "3 / 4", // span from column 3 to column 4
    gridRow: "1 / -1", // span from row 1 to the last row
    zIndex: "100", // set a high value to put the line in front of other elements
    justifySelf: "center",
  };

  const gridStyle = {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 30% 1fr  1fr",
    gap: "1px",
    padding: "0px",
    width: windowSize[0] < 991 ? "100%" : "80%",
  };

  const cardStyle = {
    background: "#e5e5e5",
    width: "100%",
    height: windowSize[0] > 991 ? "140px" : "100px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "25px",
    cursor: "pointer",
    zIndex: "100",
  };

  const getPositionRow = (currentPosition) => {
    if (currentPosition?.Trailer && !currentPosition?.Steering) {
      return currentPosition?.axle + 60;
    }
    if (currentPosition?.Trailer) {
      return currentPosition?.axle + 0;
    }
    if (!currentPosition?.Trailer && !currentPosition?.Steering) {
      return currentPosition?.axle + 20;
    }
    if (currentPosition?.Steering) {
      return currentPosition?.axle;
    }
  };

  const getSizeGridScreen = () => {
    if (windowSize[0] < 1200) {
      return "90%";
    } else {
      return "60%";
    }
  };

  /**
   * to handle the popup of the tire
   * @param {*} p
   */
  //eslint-disable-next-line
  const handleHistoryOpenPopup = (p) => {
    setDataInfo(p);
    setHistoryIsPopupOpen(true);
  };

  /**
   * to close the popup
   */
  const handleHistoryClosePopup = () => {
    setHistoryIsPopupOpen(false);
  };
  return (
    <>
      <div style={{ width: "100%" }}>
        <h3
          style={{ marginLeft: "15px", marginTop: "10px" }}
          className="mb-4 pb-2 pb-md-0 mb-md-5 px-md-2"
        >
          {i18next.t("OverViewTitle")}{" "}
        </h3>
        <ChangeDataTires
          tires={tires}
          setTires={setTires}
          generateAxleWeightList={null}
          device={device}
        />
        <br />
        <br />
        <div style={{ textAlign: "-webkit-center" }}>
          {tires.length !== 0 && (
            <>
              <div
                style={{ marginTop: windowSize[0] < 991 ? "-50px" : "-20px" }}
              >
                <div style={{ textAlign: "-webkit-left" }}>
                  <Link to={"https://devdocs.wikiland.de/"}>
                    <div
                      style={{
                        display: "inline",
                        borderRadius: "60px",
                        boxShadow: "0 0 2px #888",
                        padding: "0.5em 0.6em",
                        zoom: 1.5,
                        cursor: "pointer",
                      }}
                    >
                      <i className="fa fa-question-circle"></i>
                    </div>
                  </Link>

                  <div
                    style={{
                      display: "inline",
                      borderRadius: "60px",
                      boxShadow: "0 0 2px #888",
                      padding: "0.5em 0.6em",
                      zoom: 1.5,
                      cursor: "pointer",
                      marginLeft: "5px",
                    }}
                    onClick={() => handleHistoryOpenPopup(tires)}
                  >
                    <i className="fa fa-history"></i>
                  </div>
                </div>

                <div
                  style={{
                    width: "100%",
                    textAlign: "right",
                    marginTop: "-43px",
                  }}
                ></div>
              </div>
            </>
          )}

          {isPopupOpen && (
            <TirePopUp
              isOpen={isPopupOpen}
              onClose={handleClosePopup}
              dataInfo={dataInfo}
              deviceId={device}
            />
          )}

          {isHistoryPopupOpen && (
            <HistoryWarnings
              isOpen={isHistoryPopupOpen}
              onClose={handleHistoryClosePopup}
              dataInfo={dataInfo}
              deviceId={device}
            />
          )}

          <br />
          <br />

          {isLoading && (
            <>
              <div
                className="loading"
                style={{
                  position: "absolute",
                  //background: "none",
                  //zIndex: "-1"
                }}
              >
                <div className="loader"></div>
                <div className="text">{i18next.t("Loading")}...</div>
              </div>
            </>
          )}

          <div
            style={{
              textAlign: "-moz-center",
              width: getSizeGridScreen(),
            }}
          >
            <font className="deviceName">{device?.name}</font>

            <div id="grid" style={gridStyle}>
              {Array.isArray(tires) &&
                tires.map((position, i) => {
                  const gridRow = getPositionRow(position);

                  return (
                    <React.Fragment key={i}>
                      <hr
                        className="horizontalLineStyleV2"
                        style={{
                          gridRow: gridRow,
                          border: position?.Trailer
                            ? "solid 2px #292727"
                            : "solid 2px gray",
                        }}
                      ></hr>

                      <div
                        onClick={() => handleOpenPopup(position)}
                        style={{
                          ...cardStyle,
                          border: tyreColor(position),
                          transform: position?.Steering && "rotate(30deg)",
                          gridColumn:
                            position?.direction === "left"
                              ? position.position
                              : position?.direction === "right" &&
                                position?.position === 1
                              ? position?.position + 4
                              : position?.position + 2,
                          gridRow: gridRow,
                        }}
                      >
                        <h3>
                          <>
                            {tyreLogo(position)}
                            <b
                              className={" value"}
                              style={{
                                fontSize: windowSize[0] > 700 ? "18px" : "10px",
                                marginLeft: "-0px",
                              }}
                            >
                              <>
                                {position?.Pressure !== null &&
                                !isNaN(position.Pressure)
                                  ? Number(position?.Pressure)
                                      ?.toFixed(1)
                                      .replace(".", ",") + " Bar"
                                  : "-"}
                                <br />
                                {position?.Temperature !== null &&
                                !isNaN(position.Temperature)
                                  ? Number(position?.Temperature)
                                      ?.toFixed(1)
                                      .replace(".", ",") + " °C"
                                  : "-"}
                              </>
                            </b>
                          </>
                        </h3>
                      </div>

                      <div style={lineStyle}></div>
                    </React.Fragment>
                  );
                })}
            </div>
          </div>

          {!isLoading && tires.length === 0 ? (
            show ? (
              <>
                <div
                  style={{ marginTop: "20px" }}
                  className="d-flex align-items-center justify-content-center"
                >
                  <div className="text-center">
                    <p className="fs-3">
                      {" "}
                      <span className="text-danger"></span>
                      {i18next.t("noTires")}{" "}
                    </p>
                    <a href="/devices" className="btn btn-primary">
                      {i18next.t("GoBack")}
                    </a>
                  </div>
                </div>
              </>
            ) : null
          ) : null}
        </div>
        <br />
        <br /> <br />
        <br /> <br />
        <br />
      </div>
    </>
  );
}
