/*
 * Project: OKIT.VCM
 *
 * Copyright 2023 by OKIT GmbH
 * All rights reserved.
 *
 * Diese Software ist urheberrechtlich geschützt.
 */
import L from "leaflet";
import { iconPinSwitchOn_cs, iconPinSwitchOff_cs } from "../../utils/iconPin";
import { getNameSpaceListApi } from "../../hooks/nameSpaceList";
import { getIntervallMeasurementApi } from "../../hooks/measurementApi";
import TimeOutToast from "../../utils/TimeOutToast";
import {
  iconstart,
  iconend,
  iconpause,
  iconTopSpeed,
} from "../../utils/iconStandard";
import i18next from "i18next";

/**
 * This component represents reset button to resset the screen zoom
 *
 * @author hatem sfar
 *
 */

/**
 *
 * @param {*} measurementDate
 * @returns
 */
function formatMeasurementDate(measurementDate) {
  const date = new Date(measurementDate);

  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Note: Months are zero-based
  const year = date.getFullYear();

  return `${hours}:${minutes} ${day}.${month}.${year}`;
}

/**
 *
 * @param {*} deviceCoordinates
 */
const addSwitchMarkers = (deviceCoordinates, markerGroup, mapRef) => {
  // add marker at the end of the last polyline
  const marker = L.marker(
    [deviceCoordinates.latitude, deviceCoordinates.longitude],
    {
      icon:
        deviceCoordinates.content === "true"
          ? iconPinSwitchOn_cs
          : iconPinSwitchOff_cs,
    }
  );
  // Extract the last word from deviceCoordinates.node
  const nodeParts = deviceCoordinates.node.split(".");
  const lastWord = nodeParts[nodeParts.length - 1];

  // Add a popup with separate lines and a line connecting the marker and popup
  const formattedDate = formatMeasurementDate(
    deviceCoordinates.measurementdate
  );
  const msg = `
        ${lastWord} : ${deviceCoordinates.content} <br/>
        Time : ${formattedDate}`;
  marker.bindPopup(msg);

  markerGroup = L.featureGroup([marker]); // Create a group to hold the marker
  markerGroup.addTo(mapRef.current);
};

/**
 *
 * @param {*} res
 * @param {Array} measurements
 * @returns {Array} Updated measurements
 */
const processResponseData = (res, measurements) => {
  const measurementData = res.measurements.map((item) => ({
    id: item.id,
    content: item.content,
    contentType: item.contentType,
    measurementdate: item.measurementdate,
    node: res.namespace,
    device: res.device,
    latitude: "",
    longitude: "",
  }));
  return measurements.concat(measurementData);
};

/**
 *
 * @param {*} dateStart
 * @param {*} dateEnd
 * @param {*} selectedDevice
 * @param {*} nodeid
 * @param {*} mapRef
 * @param {*} allCoordinates
 * @param {*} measurements
 * @param {*} markerGroup
 */
const geteventdetails = async (
  dateStart,
  dateEnd,
  selectedDevice,
  nodeid,
  mapRef,
  allCoordinates,
  measurements,
  markerGroup
) => {
  // console.log("nodeid: ", nodeid?.value?.namespace)
  // convert date
  let isoDateStart = "";
  let isoDateEnd = "";
  let pageNumber = 1;
  let nextPage = true;

  // convert the date to UTC
  isoDateStart = convertDateToISOWithOffset(dateStart);
  if (dateEnd) {
    isoDateEnd = convertDateToISOWithOffset(dateEnd);
  } else {
    isoDateEnd = null;
  }

  //console.log("allCoordinates: ", allCoordinates)
  while (nextPage) {
    let response;
    try {
      response = await getIntervallMeasurementApi(
        selectedDevice?.value?.id,
        nodeid?.value?.namespace,
        isoDateStart,
        isoDateEnd,
        pageNumber
      );

      // console.log("response:",response)
      //eslint-disable-next-line
      response.data.measurements.forEach((res) => {
        measurements = processResponseData(res, measurements);
      });

      if (nodeid) {
        if (mapRef.current) {
          mapRef.current.eachLayer((layer) => {
            if (layer instanceof L.Marker) {
              mapRef.current.removeLayer(layer);
            }
          });
        }
      }

      const deviceCoordinates = allCoordinates;
      measurements.forEach((measurement) => {
        //console.log("measurement: ", measurement)

        if (deviceCoordinates) {
          //console.log("we have coordinate for selected device")
          // Find coordinates with matching device ID and closest measurement date
          let closestCoordinate = null;
          let closestDateDifference = Infinity;

          deviceCoordinates.forEach((coordinate) => {
            //console.log("coordinate: ", coordinate)
            const coordinateDate = new Date(coordinate.measurementdate);
            const measurementDate = new Date(measurement.measurementdate);
            const dateDifferenceInSeconds = Math.abs(
              (coordinateDate - measurementDate) / 1000
            );
            if (
              coordinate.device === measurement.device &&
              dateDifferenceInSeconds <= 60 && // Check if difference is within 60 seconds
              dateDifferenceInSeconds < closestDateDifference
            ) {
              closestCoordinate = coordinate;
              closestDateDifference = dateDifferenceInSeconds;
            }
          });

          if (closestCoordinate) {
            // Update latitude and longitude in the measurement
            measurement.latitude = closestCoordinate.latitude;
            measurement.longitude = closestCoordinate.longitude;
          }
        }
      });
      measurements.forEach((measurement) => {
        if (measurement) {
          // Check if latitude and longitude are not null
          if (measurement.latitude !== null && measurement.longitude !== null) {
            // Call the addSwitchMarkers function to add markers to the map
            addSwitchMarkers(measurement, markerGroup, mapRef);
          }
        }
      });

      if (!response.data.nextPage) {
        nextPage = false;
      }
      pageNumber++;
      //console.log("new measurements: ", measurements)
    } catch (err) {
      if (err.response && err.response.status === 504) {
        console.error("Gateway Time-out: The server took too long to respond.");
        TimeOutToast();
      }
      //console.log("err")
      nextPage = false;
      if (err?.response?.status === 404) {
        nextPage = false;
      }
    }
  }
};

/**
 *
 * @param {*} selectedDevice
 * @param {*} setNodes
 * @param {*} setNodeid
 */
const getNamespaceList = async (selectedDevice, setNodes, setNodeid) => {
  const response = await getNameSpaceListApi(selectedDevice.value.id);
  const sortedNamespaces = response?.sort((a, b) => {
    const labelA = a.node.namespace?.slice()?.toLowerCase();
    const labelB = b.node.namespace?.slice()?.toLowerCase();
    return labelA?.localeCompare(labelB);
  });
  const distinctNodes = Array.from(
    new Set(sortedNamespaces.map((node) => node.node.namespace))
  ).map((namespace) =>
    sortedNamespaces.find((node) => node.node.namespace === namespace)
  );
  const filteredNodes = distinctNodes.filter(
    (node) =>
      node.node.publicAvailable === true && node.node.contentType === "BOOL"
  );
  setNodes(filteredNodes);
  setNodeid(null);
};

/**
 *
 * @param {*} coordinatesByDevice
 * @param {*} speedLimite
 * @param {*} LIMITE
 * @param {*} setAllCoordinates
 * @param {*} setPolylinesArray
 * @param {*} mapRef
 * @param {*} polylineGroup
 */
const addPolylines = (
  coordinatesByDevice,
  speedLimite,
  LIMITE,
  polylineGroup,
  setAllCoordinates
) => {
  // Add the group of polylines and markers to the map
  const deviceCoordinates = coordinatesByDevice;
  setAllCoordinates(deviceCoordinates);

  // Set lat and long for each coordinate
  const latlngs = deviceCoordinates.map((coord) => [
    coord["latitude"],
    coord["longitude"],
  ]);

  // Options for the polyline
  const polylineOptions = {
    weight: 4,
    opacity: 0.5,
    isPolyline: true,
  };

  // console.log("deviceCoordinates ", deviceCoordinates);
  // Iterate through deviceCoordinates to set color based on speed
  for (let i = 0; i < deviceCoordinates.length - 1; i++) {
    const speed = deviceCoordinates[i]["speed"];
    const color = getSpeedColor(speed, speedLimite.value, LIMITE);
    polylineOptions.color = color;

    // Create a polyline segment for this pair of coordinates with corresponding color
    const segmentLatLngs = [latlngs[i], latlngs[i + 1]];
    const polyline = L.polyline(segmentLatLngs, polylineOptions);

    // Bind tooltip with the measurement date for this coordinate
    const measurementDate = formatMeasurementDateLine(
      deviceCoordinates[i]["measurementdate"]
    );
    polyline.bindTooltip(measurementDate, {
      permanent: false,
      direction: "top",
    });

    polyline.bindPopup(`Device: ${deviceCoordinates[i]["deviceName"]}`);
    polylineGroup.addLayer(polyline);
  }
};

// Function to format measurement date (seconds, minutes, hours, and date)
const formatMeasurementDateLine = (timestamp) => {
  const date = new Date(timestamp);

  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Note: Months are zero-based
  const year = date.getFullYear();

  return `${hours}:${minutes}:${seconds} ${day}.${month}.${year}`;
};

/**
 *
 * @param {*} speed
 * @param {*} valueSpeedSelected
 * @param {*} extraRange
 * @returns
 */
const getSpeedColor = (speed, valueSpeedSelected, extraRange) => {
  if (valueSpeedSelected === 30) {
    extraRange = extraRange + 5;
  } else if (valueSpeedSelected === 40) {
    extraRange = extraRange + 10;
  }
  if (valueSpeedSelected === 75) {
    extraRange = extraRange + 25;
  }
  if (valueSpeedSelected === 110) {
    extraRange = extraRange + 50;
  }

  //console.log("extraRange: ", extraRange)
  if (isNaN(speed)) {
    return "black";
  } else if (!isNaN(speed) && speed > valueSpeedSelected) {
    return "green";
  } else if (!isNaN(speed) && speed < valueSpeedSelected - extraRange) {
    return "red";
  } else if (
    !isNaN(speed) &&
    valueSpeedSelected - extraRange <= speed <= valueSpeedSelected
  ) {
    return "blue";
  }
};

/**
 *
 * @param {*} deviceCoordinates
 * @param {*} mapRef
 * @param {*} pos
 */
const addMarkers = (
  deviceCoordinates,
  coordinateMeasurment,
  mapRef,
  pos,
  location,
  textPopUp,
  textTime,
  tourData
) => {
  const lastCoordinate = deviceCoordinates;
  // console.log("lastCoordinate: ", lastCoordinate)
  const marker = L.marker(
    [lastCoordinate["latitude"], lastCoordinate["longitude"]],
    {
      icon: createIcon(pos),
    }
  );
  const startedTime = formatMeasurementDate(
    coordinateMeasurment["measurementdate"]
  );
  const endedTime = formatMeasurementDate(
    coordinateMeasurment["measurementdate"]
  );
  let kmDrivenCalc = 0;

  if (tourData?.breaks && pos === "end") {
    for (let i = 0; i < tourData?.breaks.length; i++) {
      kmDrivenCalc =
        kmDrivenCalc + tourData?.breaks[i]?.kilometerTotalBeforeBreak;
    }
  }
  //console.log("kmDriven at stop icon: ", (tourData?.kilometerTotal - kmDrivenCalc).toFixed(1).replace('.', ','))
  const kmDrivenText =
    tourData?.breaks?.length > 0
      ? i18next.t("since_last_stop")
      : i18next.t("Kilometer_driven");
  const kmDrivenValue =
    tourData?.breaks?.length > 0
      ? (tourData?.kilometerTotal - kmDrivenCalc)?.toFixed(1).replace(".", ",")
      : tourData?.kilometerTotal?.toFixed(1).replace(".", ",");

  const msg_start = `
    ${textPopUp} <br/>
    ${textTime}: ${startedTime} <br/>
    ${i18next.t("location")}: ${location} <br/>`;

  const msg_end = `
    ${textPopUp} <br/>
    ${textTime}: ${endedTime} <br/>
    ${i18next.t("location")}: ${location} <br/>
    ${kmDrivenText} : ${kmDrivenValue} km <br/>`;

  const msg = pos === "start" ? msg_start : msg_end;

  marker.bindPopup(msg);
  const markerGroup = L.featureGroup([marker]); // Create a group to hold the marker
  markerGroup.addTo(mapRef.current);
};

/**
 *
 * @param {*} pos
 * @returns
 */
function createIcon(pos) {
  const iconSize = [30, 30];
  const icon = pos === "start" ? iconstart : iconend;
  return L.divIcon({
    className: "custom-icon",
    html: `<img src="${icon}" width= "${iconSize[0]}px" height= "${iconSize[1]}px"/>`,
    iconSize: iconSize,
    iconAnchor: [15, 15],
  });
}

/**
 *
 * @param {*} breaksCoordinates
 * @param {*} mapRef
 */
const addBreaksMarkers = (breaksCoordinates, mapRef) => {
  breaksCoordinates?.forEach((breakCoordinate) => {
    const marker = L.marker(
      [breakCoordinate["latitudeBreak"], breakCoordinate["longitudeBreak"]],
      {
        icon: createBreakIcon(),
        isBreak: true,
      }
    );

    // Add a popup with separate lines and a line connecting the marker and popup
    const startedTime = formatMeasurementDate(breakCoordinate["startedTime"]);
    const endedTime = formatMeasurementDate(breakCoordinate["endedTime"]);

    const totalMinutes = parseInt(breakCoordinate["minutesBreak"]);
    const hours = Math.floor(totalMinutes / 60);
    const remainingMinutes = totalMinutes % 60;
    const formattedTime = `${hours}:${remainingMinutes
      .toString()
      .padStart(2, "0")}`;

    const msg = `
        ${i18next.t("Stop")} <br/>
        ${i18next.t("Arrival")}: ${startedTime} <br/>
        ${i18next.t("Departure")}: ${endedTime} <br/>
        ${i18next.t("Stay")}: ${formattedTime} (h:m) <br/>
        ${i18next.t("since_last_stop")} ${breakCoordinate[
      "kilometerTotalBeforeBreak"
    ]
      ?.toFixed(1)
      .replace(".", ",")} km <br/>
        `;

    marker.bindPopup(msg);
    const markerGroup = L.featureGroup([marker]); // Create a group to hold the marker
    markerGroup.addTo(mapRef.current);
  });
};

/**
 *
 * @returns
 */
function createBreakIcon() {
  const iconSize = [20, 20];
  const icon = iconpause;
  return L.divIcon({
    className: "custom-icon",
    html: `<img src="${icon}" width= "${iconSize[0]}px" height= "${iconSize[1]}px"/>`,
    iconSize: iconSize,
    iconAnchor: [10, 10],
  });
}

/**
 *
 * @param {*} date
 * @param {*} offsetHours
 * @returns
 */
const convertDateToISOWithOffset = (date) => {
  if (!date) {
    return null;
  }
  const dateWithOffset = new Date(date);
  return dateWithOffset.toISOString();
};

/**
 *
 * @param {*} topSpeedsCoordinates
 * @param {*} mapRef
 */
//TODO: Ask olaf :maybe we can add a polyline
const addTopSpeedsMarkers = (topSpeedsCoordinates, mapRef, polylineGroup) => {
  topSpeedsCoordinates?.forEach((topSpeedCoordinate) => {
    const marker = L.marker(
      [
        topSpeedCoordinate["latitudeSpeedStart"],
        topSpeedCoordinate["longitudeSpeedStart"],
      ],
      {
        icon: createTopSpeedIcon(),
        isTopSpeed: true,
      }
    );
    // Add a popup with separate lines and a line connecting the marker and popup
    const startedTime = formatMeasurementDate(
      topSpeedCoordinate["startedTime"]
    );

    const msg = `
        ${i18next.t("Top_speed")} <br/>
        ${i18next.t("Time")}: ${startedTime} <br/>
        ${i18next.t("Speed")} ${topSpeedCoordinate["topSpeed"]} km/h <br/>
        `;

    marker.bindPopup(msg);
    const markerGroup = L.featureGroup([marker]);
    markerGroup.addTo(mapRef.current);
  });
};

/**
 *
 * @returns
 */
function createTopSpeedIcon() {
  const iconSize = [20, 20];
  const icon = iconTopSpeed;
  return L.divIcon({
    className: "custom-icon",
    html: `<img src="${icon}" width= "${iconSize[0]}px" height= "${iconSize[1]}px"/>`,
    iconSize: iconSize,
    iconAnchor: [10, 10],
  });
}

export {
  geteventdetails,
  getNamespaceList,
  addPolylines,
  addMarkers,
  addBreaksMarkers,
  addTopSpeedsMarkers,
  convertDateToISOWithOffset,
};
