/*
 * Project: OKIT.VCM
 *
 * Copyright 2023 by OKIT GmbH
 * All rights reserved.
 *
 * Diese Software ist urheberrechtlich geschützt.
 */
import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import i18next from "i18next";
import Container from "react-bootstrap/Container";
import TireChart from "./TireChart";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "rc-tooltip/assets/bootstrap.css";
import "react-datepicker/dist/react-datepicker.css";
import { Row, Col } from "react-bootstrap";
import { Button, Drawer, Form, Input, Space, Tooltip } from "antd";
import "bootstrap/dist/css/bootstrap.min.css";
import DatePicker from "react-datepicker";
import moment from "moment";
import auth from "../../auth/auth";
import ROLES from "../../utils/roles";
import { Loading } from "../../utils/loading";
import {
  getMeasurementByNameSpaceApi,
  setMeasurementByNameSpaceApi,
} from "../../hooks/measurementApi";
//eslint-disable-next-line
import { ChangeDataTires } from "../../utils/changeDataWS";
import TimeOutToast from "../../utils/TimeOutToast.js";
import { tyreLogo } from "../../utils/deviceState.js";

/**
 * This component represents the the TirePopup info.
 *
 * @author hatem sfar
 *
 */
function TirePopUp({ isOpen, onClose, dataInfo, deviceId }) {
  //eslint-disable-next-line
  const [show, setShow] = useState(isOpen);
  const [expand, setExpand] = useState(false);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [obj, setObj] = useState("");
  const [value, setValue] = useState("");
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  //eslint-disable-next-line
  const [ButtonEnable, setButtonEnable] = useState(true);
  const [pointer, setPointer] = useState("");
  const long = localStorage.getItem("lang");
  const tireTypes = [
    { value: "allSeason", label: "All Season" },
    { value: "winter", label: "Winter" },
    { value: "summer", label: "Summer" },
    { value: "M+S", label: "M+S" },
    { value: "refurbished", label: "refurbished" },
    { value: "performance", label: "Performance" },
  ];
  const [selectedTireType, setSelectedTireType] = useState("");

  //console.log("dataInfo ", dataInfo);

  // Handle the selection change
  const handleTireTypeChange = (e) => {
    if (e.target) {
      if (obj.value === e.target.value) {
        setButtonEnable(true);
      } else {
        setButtonEnable(false);
      }
      //eslint-disable-next-line
      setSelectedTireType(e.target.value);
      setValue(e.target.value);
    }
  };
  /**
   * to handle the right side popup
   * @param {*} value
   */
  const showDrawer = (value) => {
    //eslint-disable-next-line
    if (auth([ROLES.ADMIN, ROLES.OPERATOR, ROLES.USER])) {
      setIsDrawerVisible(true);
      setValue(value.value);
      setObj({
        name: value.name,
        value: value.value,
        title: value.title,
        deviceId: value.deviceId,
        type: value.type,
        unit: value.unit,
        var1: value.var1,
        axle: value.axle,
        direction: value.direction,
        position: value.position,
      });
    }
  };
  /**
   * to handle close the right side popup
   */
  const handleDrawerClose = () => {
    setIsDrawerVisible(false);
    setButtonEnable(true);
  };

  useEffect(() => {
    getMeasurement();
    auth([ROLES.ADMIN, ROLES.OPERATOR, ROLES.USER]) && setPointer("pointer");

    //eslint-disable-next-line
  }, [data.values]);

  const toggleExpand = () => setExpand(!expand);

  function handleKeyPress(event) {
    //eslint-disable-next-line
    const regex = /[0-9,\.]/g; // regex pattern to match numbers, comma and period
    const key = event.key;
    if (!regex.test(key)) {
      // if the pressed key doesn't match the regex pattern
      event.preventDefault(); // prevent the input from appearing in the field
    }
  }
  /**
   * to handle the input in the field
   * @param {*} e
   */
  const handleInputChange = (e) => {
    if (e.target) {
      if (obj.value === e.target.value) {
        setButtonEnable(true);
      } else {
        setButtonEnable(false);
      }
      //eslint-disable-next-line
      setValue(e.target.value.replace(/\,/, "."));
    } else {
      const isEq =
        new Date(e).toISOString().substring(0, 10) ===
        obj.value.substring(0, 10);
      if (isEq) {
        setButtonEnable(true);
      } else {
        setValue(e);
        setButtonEnable(false);
      }
    }
  };
  /**
   * to handle the input for boolean type
   */
  const handleInputChangeBool = (e) => {
    if (obj.value === e.target.checked) {
      setButtonEnable(true);
    } else {
      setButtonEnable(false);
    }
    setValue(e.target.checked);
  };
  /**
   * to handle the date format
   * @param {*} inputDate
   * @returns
   */
  const formatDate = (inputDate) => {
    // Use Moment.js to parse the input date string
    const date = moment(inputDate, [
      "ddd MMM DD HH:mm:ss z YYYY",
      "YYYY-MM-DD HH:mm:ss.SSS",
    ]);

    // Format the date string in the desired format
    return date.format("YYYY-MM-DD HH:mm:ss.SSS");
  };
  /**
   * to get the last recent measureument for selected namespace and device
   */
  const getMeasurement = async () => {
    const position =
      "de.okit.automotive.tpms." +
      dataInfo.var1 +
      "." +
      dataInfo.axle +
      "." +
      dataInfo.direction +
      "." +
      dataInfo.position +
      ".*" +
      "," +
      "de.okit.automotive.tire." +
      dataInfo.var1 +
      "." +
      dataInfo.axle +
      "." +
      dataInfo.direction +
      "." +
      dataInfo.position +
      ".*" +
      "," +
      "de.okit.automotive.tire." +
      dataInfo.var1 +
      "." +
      dataInfo.axle +
      ".Steering" +
      "," +
      "de.okit.automotive.tire." +
      dataInfo.var1 +
      "." +
      dataInfo.axle +
      ".Trailer" +
      "," +
      "de.okit.automotive.device.TotalKmDriven";

    getMeasurementByNameSpaceApi(deviceId.id, position)
      .then((response) => {
        response &&
          response?.forEach((info) => {
            if (info && info.namespace.endsWith("Pressure")) {
              if (info.measurements && info.measurements.length > 0) {
                data.Pressure = info?.measurements[0]?.content;
                data.Timestamp_Pressure =
                  info?.measurements[0]?.measurementdate;
              }
            }
            if (info && info.namespace.endsWith("Temperature")) {
              if (info.measurements && info.measurements.length > 0) {
                data.Temperature = info?.measurements[0]?.content;
                data.Timestamp_Temperture =
                  info?.measurements[0]?.measurementdate;
              }
            }

            if (info && info.namespace.endsWith("TemperatureAlarm")) {
              if (info.measurements && info.measurements.length > 0) {
                data.TemperatureAlarm = info?.measurements[0]?.content;
                data.Timestamp_TemperatureAlarm =
                  info?.measurements[0]?.measurementdate;
              }
            }

            if (info && info.namespace.endsWith("TemperatureWarn")) {
              if (info.measurements && info.measurements.length > 0) {
                data.TemperatureWarn = info?.measurements[0]?.content;
                data.Timestamp_TemperatureWarn =
                  info?.measurements[0]?.measurementdate;
              }
            }
            if (info && info.namespace.endsWith("PressureLowerWarn")) {
              if (info.measurements && info.measurements.length > 0) {
                data.PressureLowerWarn = info?.measurements[0]?.content;
                data.Timestamp_PressureLowerWarn =
                  info?.measurements[0]?.measurementdate;
              }
            }
            if (info && info.namespace.endsWith("PressureLowerAlarm")) {
              if (info.measurements && info.measurements.length > 0) {
                data.PressureLowerAlarm = info?.measurements[0]?.content;
                data.Timestamp_PressureLowerAlarm =
                  info?.measurements[0]?.measurementdate;
              }
            }
            if (info && info.namespace.endsWith("PressureUpperWarn")) {
              if (info.measurements && info.measurements.length > 0) {
                data.PressureUpperWarn = info?.measurements[0]?.content;
                data.Timestamp_PressureUpperWarn =
                  info?.measurements[0]?.measurementdate;
              }
            }
            if (info && info.namespace.endsWith("PressureUpperAlarm")) {
              if (info.measurements && info.measurements.length > 0) {
                data.PressureUpperAlarm = info?.measurements[0]?.content;
                data.Timestamp_PressureUpperAlarm =
                  info?.measurements[0]?.measurementdate;
              }
            }
            if (info && info.namespace.endsWith("PressureIdeal")) {
              if (info.measurements && info.measurements.length > 0) {
                data.PressureIdeal = info?.measurements[0]?.content;
                data.Timestamp_PressureIdeal =
                  info?.measurements[0]?.measurementdate;
              }
            }
            if (info && info.namespace.endsWith("PressureRecommended")) {
              if (info.measurements && info.measurements.length > 0) {
                data.PressureRecommended = info?.measurements[0]?.content;
                data.Timestamp_PressureRecommended =
                  info?.measurements[0]?.measurementdate;
              }
            }
            if (info && info.namespace.endsWith("Trailer")) {
              if (info.measurements && info.measurements.length > 0) {
                const stringValue = info?.measurements[0]?.content;
                const booleanValue = stringValue === "true";
                data.Trailer = Boolean(info.measurements && booleanValue);
                data.Timestamp_Trailer =
                  info.measurements && info?.measurements[0]?.measurementdate;
              }
            }
            if (info && info.namespace.endsWith("Steering")) {
              if (info.measurements && info.measurements.length > 0) {
                const stringValue = info?.measurements[0]?.content;
                const booleanValue = stringValue === "true";
                data.Steering = Boolean(info.measurements && booleanValue);
                data.Timestamp_Steering =
                  info.measurements && info?.measurements[0]?.measurementdate;
              }
            }
            if (info && info.namespace.endsWith("TireWidth")) {
              if (info.measurements && info.measurements.length > 0) {
                data.TireWidth = info?.measurements[0]?.content;
                data.Timestamp_TireWidth =
                  info?.measurements[0]?.measurementdate;
              }
            }
            if (info && info.namespace.endsWith("CommissioningDate")) {
              if (info.measurements && info.measurements.length > 0) {
                const formattedDatenew = formatDate(
                  info?.measurements[0]?.content
                );
                data.CommissioningDate = formattedDatenew;
                data.Timestamp_CommissioningDate =
                  info?.measurements[0]?.measurementdate;
              }
            }
            if (info && info.namespace.endsWith("ProductionDate")) {
              if (info.measurements && info.measurements.length > 0) {
                const formattedDatenew = formatDate(
                  info?.measurements[0]?.content
                );
                data.ProductionDate = formattedDatenew;
                data.Timestamp_ProductionDate =
                  info?.measurements[0]?.measurementdate;
              }
            }
            if (info && info.namespace.endsWith(".TireType")) {
              if (info.measurements && info.measurements.length > 0) {
                data.TireType = info?.measurements[0]?.content;
                data.Timestamp_TireType =
                  info?.measurements[0]?.measurementdate;
                setSelectedTireType(info?.measurements[0]?.content);
              }
            }
            if (info && info.namespace.endsWith(".Manufacturer")) {
              if (info.measurements && info.measurements.length > 0) {
                data.Manufacturer = info?.measurements[0]?.content;
                data.Timestamp_Manufacturer =
                  info?.measurements[0]?.measurementdate;
              }
            }
            if (info && info.namespace.endsWith("tpmsSensorId")) {
              if (info.measurements && info.measurements.length > 0) {
                data.tpmsSensorId = info?.measurements[0]?.content;
                data.Timestamp_TempsensorId =
                  info?.measurements[0]?.measurementdate;
              }
            }
            if (info && info.namespace.endsWith("MaxAllowedKm")) {
              if (info.measurements && info.measurements.length > 0) {
                data.MaxAllowedKm = info?.measurements[0]?.content;
                data.Timestamp_MaxAllowedKm =
                  info?.measurements[0]?.measurementdate;
              }
            }
            if (info && info.namespace.endsWith("NextMaintenanceKmLevel")) {
              if (info.measurements && info.measurements.length > 0) {
                data.NextMaintenanceKmLevel = info?.measurements[0]?.content;
                data.Timestamp_NextMaintenanceKmLevel =
                  info?.measurements[0]?.measurementdate;
              }
            }
            if (info && info.namespace.endsWith("Profiltiefe")) {
              if (info.measurements && info.measurements.length > 0) {
                data.Profiltiefe = info?.measurements[0]?.content;
                data.Timestamp_Profiltiefe =
                  info?.measurements[0]?.measurementdate;
              }
            }
            if (info && info.namespace.endsWith("InstallationOffset")) {
              if (info.measurements && info.measurements.length > 0) {
                data.InstallationOffset = info?.measurements[0]?.content;
                data.Timestamp_InstallationOffset =
                  info?.measurements[0]?.measurementdate;
              }
            }
            if (info && info.namespace.endsWith("TotalKmDriven")) {
              if (info.measurements && info.measurements.length > 0) {
                data.TotalKmDriven = info?.measurements[0]?.content;
                data.Timestamp_TotalKmDriven =
                  info?.measurements[0]?.measurementdate;
              }
            }
          });

        data.var1 = dataInfo.var1;
        data.axle = dataInfo.axle;
        data.direction = dataInfo.direction;
        data.position = dataInfo.position;

        setData(data);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response && error.response.status === 504) {
          console.error(
            "Gateway Time-out: The server took too long to respond."
          );
          TimeOutToast();
          setIsLoading(false);
        }
      });
  };
  /**
   * to update the new value for a namespace
   * @param {*} obj
   */
  const updateNamespace = async (obj) => {
    if (auth([ROLES.ADMIN, ROLES.OPERATOR, ROLES.USER])) {
      if (value !== obj.value) {
        setIsLoading(true);
        const namespace =
          obj.type === "bool"
            ? `de.okit.automotive.tire.${obj.var1}.${obj.axle}.${obj.name}`
            : `de.okit.automotive.tire.${obj.var1}.${obj.axle}.${obj.direction}.${obj.position}.${obj.name}`;
        try {
          setMeasurementByNameSpaceApi(obj.deviceId.id, value, namespace).then(
            () => {
              getMeasurement();
              setShow(true);
              setIsDrawerVisible(false);
              setButtonEnable(true);
            }
          );
        } catch (err) {
          if (err.response && err.response.status === 504) {
            console.error(
              "Gateway Time-out: The server took too long to respond."
            );
            TimeOutToast();
            setIsLoading(false);
          }
          console.log(err);
        }
      } else {
        setButtonEnable(true);
      }
    }
  };

  // Create a method to format the timestamp
  const formatTimestamp = (timestamp) => {
    return timestamp
      ? `${String(new Date(timestamp).getHours()).padStart(2, "0")}:${String(
          new Date(timestamp).getMinutes()
        ).padStart(2, "0")} ${String(new Date(timestamp).getDate()).padStart(
          2,
          "0"
        )}.${String(new Date(timestamp).getMonth() + 1).padStart(
          2,
          "0"
        )}.${new Date(timestamp).getFullYear()}`
      : "-";
  };

  return (
    <>
      {/*
      <ChangeDataTires 
      getTires={getMeasurement} />
      */}
      <Modal
        show={show}
        onHide={onClose}
        backdrop="static"
        keyboard={true}
        scrollable={true}
        footer={null}
        backdropClassName="backdrop_style"
        style={{ zIndex: 9999, marginTop: "50px", height: "90%" }}
      >
        <Modal.Header closeButton style={{}}>
          {tyreLogo(data, "popUp")}
          <Modal.Title style={{ margin: "0 auto" }}>
            <font size="5" style={{ color: "#545454" }}>
              {" "}
              {deviceId?.name}
              {!isLoading && (
                <font style={{ color: "#545454" }}>
                  {" | "} {i18next.t("TirePopUp_Axle")}{" "}
                  {data.axle ? data.axle : "-"} {" | "}{" "}
                  {i18next.t(data.direction)}
                  {" | "}{" "}
                  {data.position === 1 ? (
                    <>{i18next.t("TirePopUp_OuterTire")} </>
                  ) : (
                    <>{i18next.t("TirePopUp_InnerTire")}</>
                  )}
                </font>
              )}{" "}
            </font>
          </Modal.Title>

          <Drawer
            title={`${i18next.t("Update")} ${obj.title}`}
            open={isDrawerVisible}
            onClose={handleDrawerClose}
            zIndex={1100} // set Drawer zIndex to 1100
            getContainer={false} // prevent Drawer from being clipped by parent elements
            rootStyle={{ position: "absolute" }} // set Drawer position to absolute
          >
            <Form
              layout="vertical"
              hideRequiredMark
              initialValues={{ name: obj }}
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item>
                    {(obj.type === "float" ||
                      obj.type === "integer" ||
                      obj.type === "number") && (
                      <>
                        <div>
                          <Input
                            style={{ width: "70%" }}
                            type={
                              obj.type === "float" ||
                              obj.type === "integer" ||
                              obj.type === "number"
                                ? "text"
                                : "text"
                            }
                            name="value"
                            placeholder="Enter New Value"
                            value={
                              long === "de" ? value?.replace(/\./, ",") : value
                            }
                            onKeyPress={handleKeyPress}
                            onChange={handleInputChange}
                          />
                          {(obj.type === "float" || obj.type === "number") && (
                            <font style={{ marginLeft: "5px" }} size="2">
                              {i18next.t("Unit_Pressure")}{" "}
                            </font>
                          )}

                          {obj.type === "integer" && (
                            <font style={{ marginLeft: "5px" }} size="2">
                              {!obj.unit ? i18next.t("mm") : " km"}
                            </font>
                          )}
                        </div>
                      </>
                    )}
                    {obj.type === "numberTemp" && (
                      <>
                        <div>
                          <Input
                            style={{ width: "70%" }}
                            type="number"
                            name="value"
                            placeholder="Enter New Value"
                            value={
                              long === "de" ? value?.replace(/\./, ",") : value
                            }
                            onKeyPress={handleKeyPress}
                            onChange={handleInputChange}
                          />
                          <font style={{ marginLeft: "5px" }} size="2">
                            {i18next.t("°C")}{" "}
                          </font>
                        </div>
                      </>
                    )}
                    {obj.type === "string" && (
                      <>
                        <div>
                          <Input
                            style={{ width: "70%" }}
                            type="text"
                            name="value"
                            placeholder="Enter New Value"
                            value={
                              long === "de" ? value?.replace(/\./, ",") : value
                            }
                            onChange={handleInputChange}
                          />
                          {(obj.type === "float" || obj.type === "number") && (
                            <font style={{ marginLeft: "5px" }} size="2">
                              {i18next.t("Unit_Pressure")}{" "}
                            </font>
                          )}

                          {obj.type === "integer" && (
                            <font style={{ marginLeft: "5px" }} size="2">
                              {i18next.t("mm")}{" "}
                            </font>
                          )}
                        </div>
                      </>
                    )}

                    {obj.type === "Date" && (
                      <DatePicker
                        selected={new Date(value)}
                        onChange={handleInputChange}
                        className="inputDate"
                        dateFormat="dd.MM.yyyy"
                        calendarStartDay={1}
                      />
                    )}

                    {obj.type === "bool" && (
                      <>
                        <div className="row" style={{}}>
                          <div>
                            <Input
                              type="checkbox"
                              id="yes"
                              name="check"
                              value={"true"}
                              className="radio"
                              checked={value === true}
                              onChange={handleInputChangeBool}
                            />
                            {/* <label style={{marginLeft: "5px"}} htmlFor={"yes"}> Yes</label>*/}
                          </div>
                        </div>
                      </>
                    )}

                    {obj.type === "dropDown" && (
                      <>
                        <select
                          style={{ width: "70%" }}
                          value={selectedTireType}
                          className={"inputDate2"}
                          onChange={handleTireTypeChange}
                        >
                          <option value="" disabled>
                            Select Tire Type
                          </option>
                          {tireTypes.map((tire) => (
                            <option key={tire.value} value={tire.value}>
                              {tire.label}
                            </option>
                          ))}
                        </select>
                      </>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Space>
                <Button onClick={handleDrawerClose}>
                  {i18next.t("Cancel")}
                </Button>
                <Tooltip
                  placement="right"
                  overlay={ButtonEnable ? i18next.t("errorbutton") : ""}
                >
                  <Button
                    onClick={() => updateNamespace(obj)}
                    type="primary"
                    disabled={ButtonEnable}
                  >
                    {i18next.t("Update")}
                  </Button>
                </Tooltip>
              </Space>
            </Form>
          </Drawer>
        </Modal.Header>
        <Modal.Body
          style={
            {
              //overflow: "scroll"
            }
          }
        >
          <Tabs
            defaultActiveKey="home"
            id="fill-tab-example"
            className="mb-3"
            justify
          >
            <Tab eventKey="home" title={i18next.t("TAB_Home")}>
              {isLoading && <Loading pos="absolute" />}

              <Container fluid>
                <Row>
                  <font size="5" style={{ textAlign: "center" }}>
                    {i18next.t("TirePopUp_Basic")}
                  </font>
                </Row>
                <Row style={{ marginTop: "10px" }}>
                  <Col>
                    <font size="3" style={{}}>
                      {i18next.t("TirePopUp_SensorId")}:
                    </font>
                  </Col>
                  <Col>
                    <Tooltip
                      onClick={() =>
                        auth([ROLES.ADMIN, ROLES.OPERATOR, ROLES.USER]) &&
                        showDrawer({
                          value: data.tpmsSensorId ? data.tpmsSensorId : null,
                          title: "Tpms SensorId",
                          name: "tpmsSensorId",
                          deviceId: deviceId,
                          type: "string",
                          var1: data.var1,
                          axle: data.axle,
                          direction: data.direction,
                          position: data.position,
                        })
                      }
                      placement="right"
                      zIndex={9999}
                      title={
                        data?.Timestamp_TempsensorId
                          ? formatTimestamp(data?.Timestamp_TempsensorId)
                          : "-"
                      }
                    >
                      <font size="3" style={{ cursor: pointer }}>
                        {data?.tpmsSensorId ? data?.tpmsSensorId : "-"}
                      </font>
                    </Tooltip>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <font size="3" style={{}}>
                      {i18next.t("TirePopUp_Pressure")}:
                    </font>
                  </Col>
                  <Col>
                    <font size="3" style={{}}>
                      {data?.Pressure && !isNaN(data.Pressure)
                        ? Number(data?.Pressure)?.toFixed(2)?.replace(".", ",")
                        : "-"}
                    </font>

                    <> </>
                    <font size="2" style={{ marginLeft: "5px" }}>
                      {i18next.t("Unit_Pressure")}
                    </font>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <font size="3" style={{}}>
                      {i18next.t("Timestamp_Pressure")}:
                    </font>
                  </Col>
                  <Col>
                    <font size="3" style={{}}>
                      {data?.Timestamp_Pressure
                        ? formatTimestamp(data?.Timestamp_Pressure)
                        : "-"}
                    </font>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <font size="3" style={{}}>
                      {i18next.t("TirePopUp_Temperature")}:
                    </font>
                  </Col>
                  <Col>
                    <font size="3" style={{}}>
                      {data?.Temperature && !isNaN(data.Temperature)
                        ? Number(data?.Temperature)
                            ?.toFixed(2)
                            .replace(".", ",")
                        : "-"}
                      <> </>
                    </font>
                    <font size="2" style={{ marginLeft: "5px" }}>
                      {i18next.t("Unit_Temperature")}
                    </font>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <font size="3" style={{}}>
                      {i18next.t("Timestamp_Temperature")}:
                    </font>
                  </Col>
                  <Col>
                    <font size="3" style={{}}>
                      {data?.Timestamp_Temperture &&
                        formatTimestamp(data?.Timestamp_Temperture)}
                    </font>
                  </Col>
                </Row>
                <hr
                  style={{
                    background: "#545454",
                    height: "3px",
                    border: "none",
                    margin: "3",
                  }}
                />
              </Container>
              {expand && (
                <Container fluid>
                  <Row>
                    <font size="5" style={{ textAlign: "center" }}>
                      {i18next.t("TirePopUp_General")}
                    </font>
                  </Row>

                  <Row style={{ marginTop: "10px" }}>
                    <Col>
                      <font size="3" style={{}}>
                        {i18next.t("TirePopUp_TireType")}:
                      </font>
                    </Col>
                    <Col>
                      <Tooltip
                        onClick={() =>
                          auth([ROLES.ADMIN, ROLES.OPERATOR, ROLES.USER]) &&
                          localStorage.getItem("user") !== "Anonymous" &&
                          showDrawer({
                            value: data.TireType ? data.TireType : null,
                            name: "TireType",
                            title: "Tyre Type",
                            deviceId: deviceId,
                            type: "dropDown",
                            var1: data.var1,
                            axle: data.axle,
                            direction: data.direction,
                            position: data.position,
                          })
                        }
                        placement="right"
                        zIndex={9999}
                        title={
                          data?.Timestamp_TireType
                            ? formatTimestamp(data?.Timestamp_TireType)
                            : "-"
                        }
                      >
                        <font
                          style={{
                            cursor: pointer,
                          }}
                          size="3"
                        >
                          {data?.TireType ? data.TireType : "-"}
                        </font>
                      </Tooltip>
                    </Col>
                  </Row>

                  <Row style={{ marginTop: "10px" }}>
                    <Col>
                      <font size="3" style={{}}>
                        {i18next.t("TirePopUp_Manufacturer")}:
                      </font>
                    </Col>
                    <Col>
                      <Tooltip
                        onClick={() =>
                          auth([ROLES.ADMIN, ROLES.OPERATOR, ROLES.USER]) &&
                          showDrawer({
                            value: data.Manufacturer ? data.Manufacturer : null,
                            name: "Manufacturer",
                            title: "Manufacturer",
                            deviceId: deviceId,
                            type: "string",
                            var1: data.var1,
                            axle: data.axle,
                            direction: data.direction,
                            position: data.position,
                          })
                        }
                        placement="right"
                        zIndex={9999}
                        title={
                          data?.Timestamp_Manufacturer
                            ? formatTimestamp(data?.Timestamp_Manufacturer)
                            : "-"
                        }
                      >
                        <font
                          style={{
                            cursor: pointer,
                          }}
                          size="3"
                        >
                          {data?.Manufacturer ? data.Manufacturer : "-"}
                        </font>
                      </Tooltip>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <font size="3" style={{}}>
                        {i18next.t("TirePopUp_ProductionDate")}:
                      </font>
                    </Col>
                    <Col>
                      <Tooltip
                        onClick={() =>
                          auth([ROLES.ADMIN, ROLES.OPERATOR, ROLES.USER]) &&
                          showDrawer({
                            value: data.ProductionDate
                              ? data.ProductionDate
                              : "2022-06-04 13:57:00.000",
                            title: "Production Date",
                            name: "ProductionDate",
                            deviceId: deviceId,
                            type: "Date",
                            var1: data.var1,
                            axle: data.axle,
                            direction: data.direction,
                            position: data.position,
                          })
                        }
                        placement="right"
                        zIndex={9999}
                        title={
                          data?.Timestamp_ProductionDate
                            ? formatTimestamp(data?.Timestamp_ProductionDate)
                            : "-"
                        }
                      >
                        <font size="3" style={{ cursor: pointer }}>
                          {data?.ProductionDate
                            ? formatTimestamp(data?.ProductionDate)
                            : "-"}
                        </font>
                      </Tooltip>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <font size="3" style={{}}>
                        {i18next.t("TirePopUp_CommissioningDate")}:
                      </font>
                    </Col>
                    <Col>
                      <Tooltip
                        onClick={() =>
                          auth([ROLES.ADMIN, ROLES.OPERATOR, ROLES.USER]) &&
                          showDrawer({
                            value: data.CommissioningDate
                              ? data.CommissioningDate
                              : +new Date().getFullYear() +
                                "-" +
                                String(new Date().getMonth() + 1).padStart(
                                  2,
                                  "0"
                                ) +
                                "-" +
                                String(new Date().getDate()).padStart(2, "0") +
                                " 00:00:00.000",

                            name: "CommissioningDate",
                            title: "Commissioning Date",
                            deviceId: deviceId,
                            type: "Date",
                            var1: data.var1,
                            axle: data.axle,
                            direction: data.direction,
                            position: data.position,
                          })
                        }
                        placement="right"
                        zIndex={9999}
                        overlay={
                          data?.Timestamp_CommissioningDate
                            ? formatTimestamp(data?.Timestamp_CommissioningDate)
                            : "-"
                        }
                      >
                        <font size="3" style={{ cursor: pointer }}>
                          {data?.CommissioningDate
                            ? String(
                                new Date(data?.CommissioningDate).getDate()
                              ).padStart(2, "0") +
                              "." +
                              String(
                                new Date(data?.CommissioningDate).getMonth() + 1
                              ).padStart(2, "0") +
                              "." +
                              new Date(data?.CommissioningDate).getFullYear()
                            : "-"}
                        </font>
                      </Tooltip>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <font size="3" style={{}}>
                        {i18next.t("TirePopUp_TireWidth")}:
                      </font>
                    </Col>
                    <Col>
                      <Tooltip
                        onClick={() =>
                          auth([ROLES.ADMIN, ROLES.OPERATOR, ROLES.USER]) &&
                          showDrawer({
                            value: data.TireWidth ? data.TireWidth : null,
                            title: "Tire Width",
                            name: "TireWidth",
                            deviceId: deviceId,
                            type: "integer",
                            var1: data.var1,
                            axle: data.axle,
                            direction: data.direction,
                            position: data.position,
                          })
                        }
                        placement="right"
                        zIndex={9999}
                        overlay={
                          data?.Timestamp_TireWidth
                            ? formatTimestamp(data?.Timestamp_TireWidth)
                            : "-"
                        }
                      >
                        <font size="3" style={{ cursor: pointer }}>
                          {data?.TireWidth ? data?.TireWidth : "-"} {"mm"}{" "}
                        </font>
                      </Tooltip>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <font size="3" style={{}}>
                        {i18next.t("TirePopUp_steering")}:
                      </font>
                    </Col>
                    <Col>
                      <Tooltip
                        onClick={() =>
                          auth([ROLES.ADMIN, ROLES.OPERATOR, ROLES.USER]) &&
                          showDrawer({
                            value: data?.Steering,
                            name: "Steering",
                            title: "Steering",
                            deviceId: deviceId,
                            type: "bool",
                            var1: data.var1,
                            axle: data.axle,
                            direction: data.direction,
                            position: data.position,
                          })
                        }
                        placement="right"
                        zIndex={9999}
                        overlay={
                          data?.Timestamp_Steering
                            ? formatTimestamp(data?.Timestamp_Steering)
                            : "-"
                        }
                      >
                        <font size="3" style={{ cursor: pointer }}>
                          {data?.Steering === false || data?.Steering === true
                            ? i18next.t(data?.Steering)
                            : "-"}
                        </font>
                      </Tooltip>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <font size="3" style={{}}>
                        {i18next.t("TirePopUp_trailer")}:
                      </font>
                    </Col>
                    <Col>
                      <Tooltip
                        onClick={() =>
                          auth([ROLES.ADMIN, ROLES.OPERATOR, ROLES.USER]) &&
                          showDrawer({
                            value: data?.Trailer,
                            name: "Trailer",
                            title: "Trailer",
                            deviceId: deviceId,
                            type: "bool",
                            var1: data.var1,
                            axle: data.axle,
                            direction: data.direction,
                            position: data.position,
                          })
                        }
                        placement="right"
                        zIndex={9999}
                        overlay={
                          data?.Timestamp_Trailer
                            ? formatTimestamp(data?.Timestamp_Trailer)
                            : "-"
                        }
                      >
                        <font size="3" style={{ cursor: pointer }}>
                          {data?.Trailer === false || data?.Trailer === true
                            ? i18next.t(data?.Trailer)
                            : "-"}
                        </font>
                      </Tooltip>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <font size="3" style={{}}>
                        {i18next.t("TirePopUp_MaxAllowedKm")}:
                      </font>
                    </Col>
                    <Col>
                      <Tooltip
                        onClick={() =>
                          auth([ROLES.ADMIN, ROLES.OPERATOR, ROLES.USER]) &&
                          showDrawer({
                            value: data.MaxAllowedKm ? data.MaxAllowedKm : null,
                            title: "Max Allowed Km",
                            name: "MaxAllowedKm",
                            deviceId: deviceId,
                            type: "integer",
                            unit: "km",
                            var1: data.var1,
                            axle: data.axle,
                            direction: data.direction,
                            position: data.position,
                          })
                        }
                        placement="right"
                        zIndex={9999}
                        overlay={
                          data?.Timestamp_MaxAllowedKm
                            ? formatTimestamp(data?.Timestamp_MaxAllowedKm)
                            : "-"
                        }
                      >
                        <font size="3" style={{ cursor: pointer }}>
                          {data?.MaxAllowedKm
                            ? Number(data?.MaxAllowedKm)
                                ?.toFixed(2)
                                .replace(".", ",")
                            : "-"}{" "}
                          {"km"}{" "}
                        </font>
                      </Tooltip>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <font size="3" style={{}}>
                        {i18next.t("TirePopUp_kmdriven")}:
                      </font>
                    </Col>
                    <Col>
                      <font size="3" style={{ cursor: pointer }}>
                        {data?.TotalKmDriven && data?.InstallationOffset
                          ? (data?.TotalKmDriven - data?.InstallationOffset)
                              ?.toFixed(2)
                              .replace(".", ",")
                          : "-"}{" "}
                        {"km"}{" "}
                      </font>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <font size="3" style={{}}>
                        {i18next.t("TirePopUp_NextMaintenanceKmLevel")}:
                      </font>
                    </Col>
                    <Col>
                      <Tooltip
                        onClick={() =>
                          auth([ROLES.ADMIN, ROLES.OPERATOR, ROLES.USER]) &&
                          showDrawer({
                            value: data.NextMaintenanceKmLevel
                              ? data.NextMaintenanceKmLevel
                              : null,
                            title: "Next Maintenance Km Level",
                            name: "NextMaintenanceKmLevel",
                            deviceId: deviceId,
                            type: "integer",
                            unit: "km",
                            var1: data.var1,
                            axle: data.axle,
                            direction: data.direction,
                            position: data.position,
                          })
                        }
                        placement="right"
                        zIndex={9999}
                        overlay={
                          data?.Timestamp_NextMaintenanceKmLevel
                            ? formatTimestamp(
                                data?.Timestamp_NextMaintenanceKmLevel
                              )
                            : "-"
                        }
                      >
                        <font size="3" style={{ cursor: pointer }}>
                          {data?.NextMaintenanceKmLevel
                            ? Number(data?.NextMaintenanceKmLevel)
                                ?.toFixed(2)
                                .replace(".", ",")
                            : "-"}{" "}
                          {"km"}{" "}
                        </font>
                      </Tooltip>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <font size="3" style={{}}>
                        {i18next.t("TirePopUp_Profiltiefe")}:
                      </font>
                    </Col>
                    <Col>
                      <Tooltip
                        onClick={() =>
                          auth([ROLES.ADMIN, ROLES.OPERATOR, ROLES.USER]) &&
                          showDrawer({
                            value: data.Profiltiefe ? data.Profiltiefe : null,
                            title: "Profiltiefe",
                            name: "Profiltiefe",
                            deviceId: deviceId,
                            type: "integer",
                            var1: data.var1,
                            axle: data.axle,
                            direction: data.direction,
                            position: data.position,
                          })
                        }
                        placement="right"
                        zIndex={9999}
                        overlay={
                          data?.Timestamp_Profiltiefe
                            ? formatTimestamp(data?.Timestamp_Profiltiefe)
                            : "-"
                        }
                      >
                        <font size="3" style={{ cursor: pointer }}>
                          {data?.Profiltiefe
                            ? Number(data?.Profiltiefe)
                                ?.toFixed(2)
                                .replace(".", ",")
                            : "-"}{" "}
                          {"mm"}{" "}
                        </font>
                      </Tooltip>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <font size="3" style={{}}>
                        {i18next.t("TirePopUp_InstallationOffset")}:
                      </font>
                    </Col>
                    <Col>
                      <Tooltip
                        onClick={() =>
                          auth([ROLES.ADMIN, ROLES.OPERATOR, ROLES.USER]) &&
                          showDrawer({
                            value: data.InstallationOffset
                              ? data.InstallationOffset
                              : null,
                            title: "Installation Offset",
                            name: "InstallationOffset",
                            deviceId: deviceId,
                            type: "integer",
                            unit: "km",
                            var1: data.var1,
                            axle: data.axle,
                            direction: data.direction,
                            position: data.position,
                          })
                        }
                        placement="right"
                        zIndex={9999}
                        overlay={
                          data?.Timestamp_InstallationOffset
                            ? formatTimestamp(
                                data?.Timestamp_InstallationOffset
                              )
                            : "-"
                        }
                      >
                        <font size="3" style={{ cursor: pointer }}>
                          {data?.InstallationOffset
                            ? Number(data?.InstallationOffset)
                                ?.toFixed(2)
                                .replace(".", ",")
                            : "-"}{" "}
                          {"km"}{" "}
                        </font>
                      </Tooltip>
                    </Col>
                  </Row>

                  <hr
                    style={{
                      background: "#545454",
                      height: "3px",
                      border: "none",
                      margin: "3",
                    }}
                  />
                  <Row>
                    <font size="5" style={{ textAlign: "center" }}>
                      {i18next.t("TirePopUp_Pressure_Configuration")}
                    </font>
                  </Row>
                  <Row style={{ marginTop: "10px" }}>
                    <Col>
                      <font size="3" style={{}}>
                        {i18next.t("TirePopUp_PressureRecommended")}:
                      </font>
                    </Col>
                    <Col>
                      <Tooltip
                        onClick={() =>
                          auth([ROLES.ADMIN, ROLES.OPERATOR, ROLES.USER]) &&
                          showDrawer({
                            value: data.PressureRecommended
                              ? data.PressureRecommended
                              : null,
                            name: "PressureRecommended",
                            title: "Recommennded",
                            deviceId: deviceId,
                            type: "number",
                            var1: data.var1,
                            axle: data.axle,
                            direction: data.direction,
                            position: data.position,
                          })
                        }
                        placement="right"
                        zIndex={9999}
                        overlay={
                          data?.Timestamp_PressureRecommended
                            ? formatTimestamp(
                                data?.Timestamp_PressureRecommended
                              )
                            : "-"
                        }
                      >
                        <font size="3" style={{ cursor: pointer }}>
                          {data.PressureRecommended &&
                          !isNaN(data.PressureRecommended)
                            ? Number(data?.PressureRecommended)
                                ?.toFixed(2)
                                .replace(".", ",")
                            : "-"}
                          <font size="2" style={{ marginLeft: "5px" }}>
                            {i18next.t("Unit_Pressure")}
                          </font>
                        </font>
                      </Tooltip>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <font size="3" style={{}}>
                        {i18next.t("TirePopUp_PressureIdeal")}:
                      </font>
                    </Col>
                    <Col>
                      <Tooltip
                        onClick={() =>
                          auth([ROLES.ADMIN, ROLES.OPERATOR, ROLES.USER]) &&
                          showDrawer({
                            value: data.PressureIdeal
                              ? data.PressureIdeal
                              : null,
                            name: "PressureIdeal",
                            title: "Ideal",
                            deviceId: deviceId,
                            type: "number",
                            var1: data.var1,
                            axle: data.axle,
                            direction: data.direction,
                            position: data.position,
                          })
                        }
                        placement="right"
                        zIndex={9999}
                        overlay={
                          data?.Timestamp_PressureIdeal
                            ? formatTimestamp(data?.Timestamp_PressureIdeal)
                            : "-"
                        }
                      >
                        <font size="3" style={{ cursor: pointer }}>
                          {data.PressureIdeal && !isNaN(data.PressureIdeal)
                            ? Number(data?.PressureIdeal)
                                ?.toFixed(2)
                                .replace(".", ",")
                            : "-"}
                          <font size="2" style={{ marginLeft: "5px" }}>
                            {i18next.t("Unit_Pressure")}
                          </font>
                        </font>
                      </Tooltip>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <font size="3" style={{}}>
                        {i18next.t("TirePopUp_PressureLowerWarn")}:
                      </font>
                    </Col>
                    <Col>
                      <Tooltip
                        onClick={() =>
                          auth([ROLES.ADMIN, ROLES.OPERATOR, ROLES.USER]) &&
                          showDrawer({
                            value: data.PressureLowerWarn
                              ? data.PressureLowerWarn
                              : null,
                            name: "PressureLowerWarn",
                            title: "Lower Warnung",
                            deviceId: deviceId,
                            type: "number",
                            var1: data.var1,
                            axle: data.axle,
                            direction: data.direction,
                            position: data.position,
                          })
                        }
                        placement="right"
                        zIndex={9999}
                        overlay={
                          data?.Timestamp_PressureLowerWarn
                            ? formatTimestamp(data?.Timestamp_PressureLowerWarn)
                            : "-"
                        }
                      >
                        <font size="3" style={{ cursor: pointer }}>
                          {data.PressureLowerWarn &&
                          !isNaN(data.PressureLowerWarn)
                            ? Number(data?.PressureLowerWarn)
                                ?.toFixed(2)
                                .replace(".", ",")
                            : "-"}
                          <font size="2" style={{ marginLeft: "5px" }}>
                            {i18next.t("Unit_Pressure")}
                          </font>
                        </font>
                      </Tooltip>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <font size="3" style={{}}>
                        {i18next.t("TirePopUp_PressureLowerAlarm")}:
                      </font>
                    </Col>
                    <Col>
                      <Tooltip
                        onClick={() =>
                          auth([ROLES.ADMIN, ROLES.OPERATOR, ROLES.USER]) &&
                          showDrawer({
                            value: data.PressureLowerAlarm
                              ? data.PressureLowerAlarm
                              : null,
                            name: "PressureLowerAlarm",
                            title: "Lower Alarm",
                            deviceId: deviceId,
                            type: "number",
                            var1: data.var1,
                            axle: data.axle,
                            direction: data.direction,
                            position: data.position,
                          })
                        }
                        placement="right"
                        zIndex={9999}
                        overlay={
                          data?.Timestamp_PressureLowerAlarm
                            ? formatTimestamp(
                                data?.Timestamp_PressureLowerAlarm
                              )
                            : "-"
                        }
                      >
                        <font size="3" style={{ cursor: pointer }}>
                          {data.PressureLowerAlarm &&
                          !isNaN(data.PressureLowerAlarm)
                            ? Number(data?.PressureLowerAlarm)
                                ?.toFixed(2)
                                .replace(".", ",")
                            : "-"}
                          <font size="2" style={{ marginLeft: "5px" }}>
                            {i18next.t("Unit_Pressure")}
                          </font>
                        </font>
                      </Tooltip>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <font size="3" style={{}}>
                        {i18next.t("TirePopUp_PressureUpperWarn")}:
                      </font>
                    </Col>
                    <Col>
                      <Tooltip
                        onClick={() =>
                          auth([ROLES.ADMIN, ROLES.OPERATOR, ROLES.USER]) &&
                          showDrawer({
                            value: data.PressureUpperWarn
                              ? data.PressureUpperWarn
                              : null,
                            name: "PressureUpperWarn",
                            title: "Upper Warn",
                            deviceId: deviceId,
                            type: "number",
                            var1: data.var1,
                            axle: data.axle,
                            direction: data.direction,
                            position: data.position,
                          })
                        }
                        placement="right"
                        zIndex={9999}
                        overlay={
                          data?.Timestamp_PressureUpperWarn
                            ? formatTimestamp(data?.Timestamp_PressureUpperWarn)
                            : "-"
                        }
                      >
                        <font size="3" style={{ cursor: pointer }}>
                          {data.PressureUpperWarn &&
                          !isNaN(data.PressureUpperWarn)
                            ? Number(data?.PressureUpperWarn)
                                ?.toFixed(2)
                                .replace(".", ",")
                            : "-"}
                          <font size="2" style={{ marginLeft: "5px" }}>
                            {i18next.t("Unit_Pressure")}
                          </font>
                        </font>
                      </Tooltip>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <font size="3" style={{}}>
                        {i18next.t("TirePopUp_PressureUpperAlarm")}:
                      </font>
                    </Col>
                    <Col>
                      <Tooltip
                        onClick={() =>
                          auth([ROLES.ADMIN, ROLES.OPERATOR, ROLES.USER]) &&
                          showDrawer({
                            value: data.PressureUpperAlarm
                              ? data.PressureUpperAlarm
                              : null,
                            name: "PressureUpperAlarm",
                            title: "Upper Alarm",
                            deviceId: deviceId,
                            type: "number",
                            var1: data.var1,
                            axle: data.axle,
                            direction: data.direction,
                            position: data.position,
                          })
                        }
                        placement="right"
                        zIndex={9999}
                        overlay={
                          data?.Timestamp_PressureUpperAlarm
                            ? formatTimestamp(
                                data?.Timestamp_PressureUpperAlarm
                              )
                            : "-"
                        }
                      >
                        <font size="3" style={{ cursor: pointer }}>
                          {data.PressureUpperAlarm &&
                          !isNaN(data.PressureUpperAlarm)
                            ? Number(data?.PressureUpperAlarm)
                                ?.toFixed(2)
                                .replace(".", ",")
                            : "-"}
                          <font size="2" style={{ marginLeft: "5px" }}>
                            {i18next.t("Unit_Pressure")}
                          </font>
                        </font>
                      </Tooltip>
                    </Col>
                  </Row>

                  <hr
                    style={{
                      background: "#545454",
                      height: "3px",
                      border: "none",
                      margin: "3",
                    }}
                  />
                  <Row>
                    <font size="5" style={{ textAlign: "center" }}>
                      {i18next.t("TirePopUp_Temperature_Configuration")}
                    </font>
                  </Row>
                  <Row style={{ marginTop: "10px" }}>
                    <Col>
                      <font size="3" style={{}}>
                        {i18next.t("TirePopUp_TemperatureWarn")}:
                      </font>
                    </Col>
                    <Col>
                      <Tooltip
                        onClick={() =>
                          auth([ROLES.ADMIN, ROLES.OPERATOR, ROLES.USER]) &&
                          showDrawer({
                            value: data.TemperatureWarn
                              ? data.TemperatureWarn
                              : null,
                            name: "TemperatureWarn",
                            title: "Temperature Warn",
                            deviceId: deviceId,
                            type: "numberTemp",
                            var1: data.var1,
                            axle: data.axle,
                            direction: data.direction,
                            position: data.position,
                          })
                        }
                        placement="right"
                        zIndex={9999}
                        overlay={
                          data?.Timestamp_TemperatureWarn
                            ? formatTimestamp(data?.Timestamp_TemperatureWarn)
                            : "-"
                        }
                      >
                        <font size="3" style={{ cursor: pointer }}>
                          {data.TemperatureWarn && !isNaN(data.TemperatureWarn)
                            ? Number(data?.TemperatureWarn)
                                ?.toFixed(2)
                                .replace(".", ",")
                            : "-"}
                          <font size="2" style={{ marginLeft: "5px" }}>
                            {i18next.t("°C")}
                          </font>
                        </font>
                      </Tooltip>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <font size="3" style={{}}>
                        {i18next.t("TirePopUp_TemperatureAlarm")}:
                      </font>
                    </Col>
                    <Col>
                      <Tooltip
                        onClick={() =>
                          auth([ROLES.ADMIN, ROLES.OPERATOR, ROLES.USER]) &&
                          showDrawer({
                            value: data.TemperatureAlarm
                              ? data.TemperatureAlarm
                              : null,
                            name: "TemperatureAlarm",
                            title: "Temperature Alarm",
                            deviceId: deviceId,
                            type: "numberTemp",
                            var1: data.var1,
                            axle: data.axle,
                            direction: data.direction,
                            position: data.position,
                          })
                        }
                        placement="right"
                        zIndex={9999}
                        overlay={
                          data?.Timestamp_TemperatureAlarm
                            ? formatTimestamp(data?.Timestamp_TemperatureAlarm)
                            : "-"
                        }
                      >
                        <font size="3" style={{ cursor: pointer }}>
                          {data.TemperatureAlarm &&
                          !isNaN(data.TemperatureAlarm)
                            ? Number(data?.TemperatureAlarm)
                                ?.toFixed(2)
                                .replace(".", ",")
                            : "-"}
                          <font size="2" style={{ marginLeft: "5px" }}>
                            {i18next.t("°C")}
                          </font>
                        </font>
                      </Tooltip>
                    </Col>
                  </Row>
                </Container>
              )}
              {expand && (
                <hr
                  style={{
                    background: "#545454",
                    height: "3px",
                    border: "none",
                    margin: "3",
                  }}
                />
              )}

              <Button
                variant="link"
                style={{ color: "#545454" }}
                onClick={toggleExpand}
              >
                {expand
                  ? i18next.t("btn_show_less")
                  : i18next.t("btn_show_more")}
              </Button>
            </Tab>

            <Tab eventKey="chart" title={i18next.t("TAB_Chart")}>
              <Container fluid>
                <TireChart tire={data} device={deviceId} />
              </Container>
            </Tab>
          </Tabs>
        </Modal.Body>
        <Modal.Footer hidden={true}></Modal.Footer>
      </Modal>
    </>
  );
}

export default TirePopUp;
